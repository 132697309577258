import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";

import {
  MetadataState,
  fetchAllPumps,
  logOut,
  SelectionState,
  showStatusModal,
} from "../../NewStore";

import { useSnapshot } from "valtio";

import { MainConnections } from "../Selectors/MainConnections";
import { PipeConnections } from "../Selectors/PipeConnections";
import { DatasheetSelector } from "../Selectors/DatasheetSelector";
import { ImageSelector } from "../Selectors/ImageSelector";
import { SaveModal } from "../SaveModal";

export const NewPump = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const [save, setSave] = useState(false);
  const [filesUpload, setFilesUpload] = useState(true);
  const [newPump, setNewPump] = useState({
    id: undefined,
    name: undefined,
    brand: undefined,
    type: MetadataSnap.types[0],
    rsk: "",
    overallLength: "",
    mainsConnection: 1,
    pipeConnection: 1,
    eei: "",
    mei: "",
    efficiencyIndex: "",
    fluidTemperatureMin: "",
    fluidTemperatureMax: "",
    series: "",
    subSeries: "",
    model: "",
    modelExecution: "",
    image: "",
    datasheet: "",
    validated: 0,
  });
  const [errMsg, setErrMsg] = useState(null);
  const [errclass, setErrClass] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [uploadImage, setUploadImage] = useState();

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };
  const changePdfName = (e) => {
    document.getElementById("NewPumppdfName").innerHTML =
      e.target.files[0].name;
  };

  useEffect(() => {
    if (uploadImage) {
      document.getElementById("NewPumpimageName").innerHTML = uploadImage;
    }
  }, [uploadImage]);

  const changeImageName = (e) => {
    setNewPump({ ...newPump, image: e.target.files[0].name });
    setUploadImage(e.target.files[0].name);
  };

  const getAutoGenId = () => {
    if (document.getElementById("autoID").checked) {
      fetch(`/api/admin/pumps/autoid`)
        .then((response) => response.json())
        .then((data) => {
          setNewPump({
            ...newPump,
            id: data,
            image: null,
            datasheet: null,
          });
          document.getElementById("id").value = data;
          document.getElementById("id").disabled = "disabled";
        })

        .catch((err) => console.log(err));
    } else {
      document.getElementById("id").disabled = "";
    }
    setFilesUpload(!filesUpload);
  };

  const validateForm = () => {
    if (
      !(
        typeof newPump.name === "string" &&
        typeof newPump.id === "string" &&
        typeof newPump.brand === "string" &&
        typeof newPump.type === "string"
      )
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }

    return (
      typeof newPump.name === "string" &&
      typeof newPump.id === "string" &&
      typeof newPump.brand === "string" &&
      typeof newPump.type === "string"
    );
  };

  const createNewPump = async (value) => {
    fetch(`/api/admin/pumps`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(newPump),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        setSave(!save);
        fetchAllPumps();
        SelectionState.selectedPump = newPump.id;
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (!uploadImage) {
      showImage(newPump.image);
    }
  }, [newPump.image]);

  const showImage = (image) => {
    fetch(`/api/admin/file/${image}`)
      .then((response) => response.blob())
      .then((data) => {
        //setImg(data);
        //document.getElementById("test").src = URL.createObjectURL(data);
        setPreviewImage(URL.createObjectURL(data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Add new pump</h3>

        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}

        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <table className="table table-sm">
          <tbody>
            <tr>
              <td>
                <label htmlFor="productName">Product name</label>
              </td>
              <td>
                <input
                  id="productName"
                  placeholder="Product name"
                  type="text"
                  onChange={(event) => {
                    setNewPump({ ...newPump, name: event.target.value });
                  }}
                  style={errclass}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="id">ID</label>
              </td>
              <td>
                <input
                  id="id"
                  placeholder="ID"
                  type="text"
                  onChange={(event) => {
                    setNewPump({ ...newPump, id: event.target.value });
                  }}
                  style={errclass}
                />
              </td>
              <td
                style={{
                  fontSize: "0.8rem",
                  verticalAlign: "middle",
                }}
                colSpan="2"
              >
                Use this for{" "}
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  your own pumps
                </span>{" "}
                with your own article number
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="generateId">Auto generate an ID</label>
              </td>
              <td>
                <input
                  id="autoID"
                  type="checkbox"
                  onClick={getAutoGenId}
                  value={newPump.id}
                  className="checkbox"
                />
              </td>
              <td
                style={{
                  fontSize: "0.8rem",
                  verticalAlign: "middle",
                  marginLeft: "0.3rem",
                }}
              >
                Use this{" "}
                <span
                  style={{ textDecoration: "underline", fontWeight: "700" }}
                >
                  only
                </span>{" "}
                for competitors pumps
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="rsk">RSK</label>
              </td>
              <td>
                <input
                  id="rsk"
                  placeholder="RSK"
                  type="text"
                  onChange={(event) => {
                    setNewPump({ ...newPump, rsk: event.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="overallLength">Overall length</label>
              </td>
              <td>
                <input
                  id="overallLength"
                  type="number"
                  placeholder="Overall length in mm"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      overallLength: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="eei">EEI</label>
              </td>
              <td>
                <input
                  id="eei"
                  placeholder="EEI"
                  type="text"
                  onChange={(event) => {
                    setNewPump({ ...newPump, eei: event.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="mei">MEI</label>
              </td>
              <td>
                <input
                  id="mei"
                  placeholder="MEI"
                  type="text"
                  onChange={(event) => {
                    setNewPump({ ...newPump, mei: event.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="efficiencyIndex">Efficiency index</label>
              </td>
              <td>
                <input
                  id="efficiencyIndex"
                  placeholder="Efficiency index"
                  type="text"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      efficiencyIndex: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="fluidTemperatureMin">
                  Fluid temperature Min
                </label>
              </td>
              <td>
                <input
                  type="text"
                  id="fluidTemperatureMin"
                  placeholder="Fluid temperature Min"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      fluidTemperatureMin: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="fluidTemperatureMax">
                  Fluid temperature Max
                </label>
              </td>
              <td>
                <input
                  id="fluidTemperatureMax"
                  placeholder="Fluid temperature Max"
                  type="text"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      fluidTemperatureMax: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="brand">Brands</label>
              </td>
              <td>
                <select
                  style={errclass}
                  onChange={(event) => {
                    setNewPump({ ...newPump, brand: event.target.value });
                  }}
                >
                  <option hidden>Select brand</option>
                  {Object.entries(MetadataSnap.brands).map(([k, v], idx) => (
                    <option key={idx} value={k}>
                      {v}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="mainsConnection">Main connection</label>
              </td>
              <td>
                <MainConnections setNewPump={setNewPump} newPump={newPump} />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="pipeConnection">Pipe connection</label>
              </td>
              <td>
                <PipeConnections setNewPump={setNewPump} newPump={newPump} />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="brand">Types</label>
              </td>
              <td>
                <select
                  style={errclass}
                  onChange={(event) => {
                    setNewPump({ ...newPump, type: event.target.value });
                  }}
                >
                  <option hidden>Select type</option>
                  {Object.entries(MetadataSnap.types).map(([k, v], idx) => (
                    <option key={idx} value={k}>
                      {v}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="series">Series</label>
              </td>
              <td>
                <input
                  id="series"
                  type="text"
                  placeholder="Series"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      series: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="subSeries">Subseries</label>
              </td>
              <td>
                <input
                  className="changeBorder"
                  id="subSeries"
                  type="text"
                  placeholder="Subseries"
                  name="subSeries"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      subSeries: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="model">Model</label>
              </td>
              <td>
                <input
                  className="changeBorder"
                  id="model"
                  type="text"
                  placeholder="Model"
                  name="model"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      model: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="modelExecution">Model execution</label>
              </td>
              <td>
                <input
                  className="changeBorder"
                  id="modelExecution"
                  type="text"
                  placeholder="Model execution"
                  name="modelExecution"
                  onChange={(event) => {
                    setNewPump({
                      ...newPump,
                      modelExecution: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            {filesUpload && (
              <>
                <tr>
                  <td>
                    <label htmlFor="imgSelector">Select Image</label>
                  </td>
                  <td>
                    <ImageSelector
                      setNewPump={setNewPump}
                      newPump={newPump}
                      showImage={showImage}
                      setUploadImage={setUploadImage}
                    />
                  </td>
                  <td>
                    <label
                      htmlFor="NewPumpimage"
                      className="inputLabel"
                      style={{ paddingRight: "5.7rem" }}
                    >
                      Upload Image
                      <input
                        id="NewPumpimage"
                        type="file"
                        accept="image/*"
                        onChange={(e) => changeImageName(e)}
                        multiple
                        className="fileInput"
                      />
                    </label>
                  </td>
                  {uploadImage && (
                    <td>
                      <p id="NewPumpimageName" style={{ margin: "0" }}></p>
                    </td>
                  )}
                  {!uploadImage && (
                    <td style={{ position: "relative" }}>
                      <img src={previewImage} alt="" className="pumpImage" />
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <label htmlFor="datasheetSelector">Select Datasheet</label>
                  </td>
                  <td>
                    <DatasheetSelector
                      setNewPump={setNewPump}
                      newPump={newPump}
                    />
                  </td>
                  <td>
                    <label htmlFor="NewPumpdatasheet" className="inputLabel">
                      Upload pdf file
                      <input
                        id="NewPumpdatasheet"
                        type="file"
                        accept=".pdf"
                        className="fileInput"
                        onChange={changePdfName}
                      />
                    </label>
                  </td>
                  <td>
                    <p id="NewPumppdfName" style={{ margin: "0" }}></p>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <label htmlFor="validatedData">Validated data</label>
              </td>
              <td>
                <input
                  checked={!!newPump.validated}
                  onChange={() =>
                    setNewPump({
                      ...newPump,
                      validated: newPump.validated ? 0 : 1,
                    })
                  }
                  type="checkbox"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
            } else {
              setErrMsg("Please fill up all required fields marked red");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={createNewPump} />
      )}
    </div>
  );
};
