import React, { useState } from "react";

import { UpdateReplacementPump } from "../Edit/UpdateReplacementPump";

import { useSnapshot } from "valtio";
import { ReplacementPumpsState } from "../../NewStore";

export const ReplacementPumpsTab = () => {
  const replacementPumps = useSnapshot(ReplacementPumpsState);

  const [updateReplacement, setUpdateReplacement] = useState(false);
  const [replacementPumpToEditId, setReplacementPumpToEditId] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const indexOfLastPump = currentPage * perPage;
  const indexOfFirstPump = indexOfLastPump - perPage;

  const [filterQuery, setFilterQuery] = useState("");

  const allReplacementPumps = replacementPumps.allReplacements.filter((el) => {
    let regex = new RegExp(filterQuery, "ig");
    return el.id.match(regex) || el.name.match(regex);
  });

  // const currentReplacementPumps = replacementPumps.allReplacements.slice(indexOfFirstPump, indexOfLastPump);
  const currentReplacementPumps = allReplacementPumps.slice(
    indexOfFirstPump,
    indexOfLastPump
  );
  const renderedReplacementPumps = currentReplacementPumps.map(
    (replacementPump, idx) => {
      return (
        <tr key={idx}>
          <td className="p-0">{replacementPump.id}</td>
          <td className="p-0">{replacementPump.name}</td>
          <td className="text-center p-0">{replacementPump.count}</td>

          <td className="text-center p-0">
            <button
              className="btn addButton"
              onClick={() => {
                setUpdateReplacement(true);
                setReplacementPumpToEditId(replacementPump.id);
              }}
            >
              Edit
            </button>
          </td>
        </tr>
      );
    }
  );

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allReplacementPumps.length / perPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (event) => {
    return setCurrentPage(Number(event.target.id));
  };
  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        className="btn btn-outline-secondary m-1"
        key={number}
        id={number}
        onClick={handlePageClick}
      >
        {number}
      </li>
    );
  });

  return (
    <div className="Wrapper">
      <section>
        <h3>Replacements</h3>
      </section>

      <section>
        <table className="table table-hover align-middle">
          <thead className="align-middle">
            <tr>
              <td>Pump Id</td>
              <td>Name</td>
              <td>Connencted Pumps</td>
              <td className="text-center" colSpan="2">
                <input
                  className="form-control"
                  value={filterQuery}
                  onChange={(e) => setFilterQuery(e.target.value)}
                  type="search"
                  placeholder="Filter by ID or Name"
                />
              </td>
            </tr>
          </thead>
          <tbody>{renderedReplacementPumps}</tbody>
        </table>
      </section>
      <section>{renderPageNumbers}</section>
      {updateReplacement && (
        <UpdateReplacementPump
          toggleModal={() => setUpdateReplacement(!updateReplacement)}
          id={replacementPumpToEditId}
        />
      )}
    </div>
  );
};
