import React from "react";

import "../../App.sass";

import { NavLink, Redirect } from "react-router-dom";
import { LogInState } from "../../NewStore";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Navigation } from "../Navigation";
import { useSnapshot } from "valtio";

export const SideNav = () => {
  const loginData = useSnapshot(LogInState);

  const logOut = () => {
    sessionStorage.removeItem("token");
    LogInState.status = false;
    LogInState.email = false;
    LogInState.role = false;
    LogInState.id = false;
    return <Redirect to="/login" />;
  };

  return (
    <>
      <div className="sidenavWrapper ">
        <nav className="nav nav-pills flex-column mb-auto">
          <ul>
            {Navigation.map((navigationObject, idx) => {
              if (navigationObject.allowedRoles.includes(loginData.role)) {
                return (
                  <li key={idx}>
                    <NavLink to={navigationObject.path}>
                      <div className="sidenavLink">
                        <navigationObject.icon className="sidenavIcon" />
                        <h3>{navigationObject.title}</h3>
                      </div>
                      <span className="spanDivider"></span>
                    </NavLink>
                  </li>
                );
              } else {
                return null;
              }
            })}

            <li>
              <NavLink to="#">
                <div className="LogoutBtnWrapper">
                  <button onClick={logOut} className="LogoutBtn">
                    <AiOutlinePoweroff style={{ fontSize: "1.5rem" }} />
                    <span className="text">Log out</span>
                  </button>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
