import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { SaveModal } from "./SaveModal";

import { DeleteModal } from "./DeleteModal";
import { UpdatedState, logOut, showStatusModal } from "../NewStore";
import { LanguageSelect } from "./Selectors/LanguageSelect";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { ImageSelector } from "./Selectors/ImageSelector";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Media = (props) => {
  const [img, setImg] = useState();
  const [pdf, setPdf] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [save, setSave] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImg, setDeleteImg] = useState(false);
  const [deleteDatasheet, setDeleteDatasheet] = useState(false);
  const [connPumpImg, setConnPumpImg] = useState(null);
  const [connPumpPdf, setConnPumpPdf] = useState(null);
  const [datasheetLanguage, setDatasheetLanguage] = useState("en");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [imagePreview, setImagePreview] = useState([]);
  const [imageName, setImageName] = useState(null);
  const pump = props?.pump;
  if (!pump) return null;

  const toggleModal = () => {
    setSave(!save);
  };

  const saveToState = () => {
    UpdatedState.newImg = img;
    UpdatedState.newPdf = pdf;

    setSave(!save);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const showDeleteImg = () => {
    setDeleteImg(true);
  };

  const showDeleteDatasheet = () => {
    setDeleteDatasheet(true);
  };

  const resetButtons = () => {
    setSelectedFiles({});
    setDeleteImg(false);
    setDeleteDatasheet(false);
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImg(e.target?.files[0]);
      document.getElementById("FileimageName").innerHTML =
        e.target?.files[0].name;
    } else {
      setImg();
      document.getElementById("FileimageName").innerHTML = "";
    }
    //  fileImageName(e);
  };

  const pdfPreview = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setPdf(e.target?.files[0]);
      document.getElementById("FilepdfName").innerHTML =
        e.target?.files[0].name;
    } else {
      setPdf();
      document.getElementById("FilepdfName").innerHTML = "";
    }
  };

  const removeSelectedImage = (e) => {
    setImg();
    document.getElementById("Fileimage").value = "";
    document.getElementById("FileimageName").innerHTML = "";
  };

  const removeSelectedPdf = (e) => {
    setPdf();
    document.getElementById("Filedatasheet").value = "";
    document.getElementById("FilepdfName").innerHTML = "";
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    if (pageNumber >= numPages) {
      setPageNumber(1);
    }
  };

  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
    if (pageNumber <= 1) {
      setPageNumber(numPages);
    }
  };
  const setPreview = (image) => {
    fetch(`/api/admin/file/${image}`)
      .then((response) => response.blob())
      .then((data) => {
        //setImg(data);
        //document.getElementById("test").src = URL.createObjectURL(data);
        setImagePreview(URL.createObjectURL(data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const uploadFiles = () => {
    let data = new FormData();
    data.append("imgfile", img);
    data.append("connectedPumpsToImage", connPumpImg);
    data.append("pdffile", pdf);
    data.append("connectedPumpsToPdf", connPumpPdf);
    data.append("datasheetLanguage", datasheetLanguage);

    fetch(`/api/admin/files`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      body: data,
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then(
        (response) => setSave(!save),
        setImg(),
        setPdf(),
        (document.getElementById("FileimageName").innerHTML = ""),
        (document.getElementById("FilepdfName").innerHTML = "")
      )
      .catch((err) => console.log(err));
  };

  return (
    <div className="media">
      <h3>Files</h3>
      <div className="mediaInput">
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="Fileimage" className="inputLabel">
                  Upload Image
                  <input
                    id="Fileimage"
                    type="file"
                    accept="image/png"
                    className="fileInput"
                    onChange={imageChange}
                  />
                </label>
              </td>
              <td>
                <p
                  id="FileimageName"
                  className="displayedNames"
                  style={{ margin: "0" }}
                ></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {img && (
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="imgConnectedTo">Connect image to pumps</label>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  id="imgConnectedTo"
                  placeholder="IDs seperated by comma ( , )"
                  onChange={(e) => setConnPumpImg(e.target.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {img && (
        <div className="preview">
          <img
            src={URL.createObjectURL(img)}
            className="imgPreview"
            alt="Thumb"
          />
          <button onClick={removeSelectedImage} className="deleteButton">
            Remove Image
          </button>
        </div>
      )}
      <div className="datasheetSelector">
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="Filedatasheet" className="inputLabel">
                  Upload pdf file
                  <input
                    id="Filedatasheet"
                    type="file"
                    accept=".pdf"
                    className="fileInput"
                    onChange={pdfPreview}
                  />
                </label>
              </td>
              <td>
                <p
                  id="FilepdfName"
                  className="displayedNames"
                  style={{ margin: "0" }}
                ></p>
              </td>
            </tr>
          </tbody>
        </table>
        {pdf && (
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="datasheetConnectedTo">
                    Datasheet connected to pumps
                  </label>
                </td>
                <td>
                  <label htmlFor="datasheetLanguage">
                    Select language for the Datasheet
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="IDs seperated by comma ( , )"
                    id="datasheetConnectedTo"
                    onChange={(e) => setConnPumpPdf(e.target.value)}
                  />
                </td>
                <td>
                  <LanguageSelect
                    defaultValue={"Select language"}
                    setLanguage={setDatasheetLanguage}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      {pdf && (
        <div className="preview">
          <div className="pageSelection">
            <button onClick={handlePreviousPage} className="switchPageButton">
              <AiOutlineArrowLeft />
            </button>
            <p>
              {pageNumber} / {numPages}
            </p>
            <button onClick={handleNextPage} className="switchPageButton">
              <AiOutlineArrowRight />
            </button>
          </div>
          <div>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
          <button onClick={removeSelectedPdf} className="deleteButton">
            Remove PDF
          </button>
        </div>
      )}
      {/* <button className="addButton" onClick={() => {setSave(true); }}> */}
      <button className="addButton" onClick={() => setSave(true)}>
        Save
      </button>
      <section className="previewSection">
        <h2>Image preview</h2>
        <ImageSelector
          setPreview={setPreview}
          imageName={imageName}
          imagePreview={imagePreview}
          setImageName={setImageName}
        />
        <img className="imagePreview" src={imagePreview} alt="" />
      </section>

      {/* ____________Delete image and pdf section_________ */}
      {/* <div className="deleteFile">
        <h3>Select images or datasheet to delete</h3>
        <div>
          <ImageSelector
            showDeleteImg={showDeleteImg}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            fileImage={selectedFiles?.image}
          />
          {deleteImg && (
            <button
              className="deleteButton"
              style={{ marginLeft: "1rem" }}
              onClick={() => setDeleteModal(true)}
            >
              Delete image
            </button>
          )}
        </div>
        <div>
          <DatasheetSelector
            showDeleteDatasheet={showDeleteDatasheet}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            fileDatasheet={selectedFiles?.datasheet}
          />
          {deleteDatasheet && (
            <button
              className="deleteButton"
              style={{ marginLeft: "1rem" }}
              onClick={() => setDeleteModal(true)}
            >
              Delete datasheet
            </button>
          )}
        </div>
        <button className="resetButton" onClick={resetButtons}>
          Reset
        </button>
      </div> */}
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={uploadFiles} />
      )}
      {deleteModal && <DeleteModal toggleModal={toggleDeleteModal} />}
    </div>
  );
};

export default Media;
