import React, { useState, useEffect } from "react";
import excelImg from "../../assets/excelexample.png";
import excelFile from "../../assets/Translations_template.xlsx";
import AssortmentExcelFile from "../../assets/Data sheet XylemPumpID_221221.xlsx";
import AddPumpTemplateUserGuide from "../../assets/add-pump-template-user-guide.pdf";
import AddPumpTemplate from "../../assets/add-pump-template.xlsx";
import AddReplacementUserGuide from "../../assets/add-replacement-relation-template-user-guide.pdf";
import AddReplacementTemplate from "../../assets/add-replacement-relation-template.xlsx";
import UpdateCsiUserGuide from "../../assets/update-pump-csi-template-user-guide.pdf";
import UpdateCsiTemplate from "../../assets/update-pump-csi-template.xlsx";
import pdfZip from "../../assets/pdf.zip";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";

const ulStyle = {
  marginLeft: "40px",
};

export const Guidelines = () => {
  return (
    <div className="Wrapper">
      <div>
        <section style={{ padding: "60px" }}>
          <h2>Procedure Guidelines</h2>
          <br></br>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b>New Language</b>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ padding: "10px" }}>
                  <h4>How to proceed?</h4>
                  <p>
                    Send an email to{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    containing the
                    <b>
                      {" "}
                      translated XLS document and data sheets in PDF format
                      (1,2)
                    </b>
                    , translated <b> sentences (3)</b> and provide the{" "}
                    <b>country-specific contact details (4)</b>
                  </p>

                  <b>Details on the documents and information needed</b>
                  <br></br>
                  <br></br>
                  <ul style={ulStyle}>
                    <p>
                      <b>1. XLS –</b> download and translate this document{" "}
                      <b>
                        <a href={excelFile} download>
                          {" "}
                          link here{" "}
                        </a>
                      </b>
                      <br></br>
                      Add another column for your language and translate each
                      row. For example:
                    </p>
                    <Image fluid src={excelImg}></Image>
                  </ul>

                  <br></br>
                  <br></br>
                  <ul style={ulStyle}>
                    <p>
                      <b>2. Datasheet PDF format –</b> download the templates
                      and translate the datasheet you need for your country{" "}
                      <b>
                        {" "}
                        <a href={pdfZip} download>
                          {" "}
                          link here{" "}
                        </a>
                      </b>
                    </p>
                  </ul>
                  <ul style={ulStyle}>
                    <b>
                      3. Sentences <br></br>Translate the 2 sentences below:
                    </b>
                    <li style={ulStyle}>
                      a. "The world's largest replacement guide for pumps"
                    </li>
                    <li style={ulStyle}>
                      b. "If you don't find a good replacement pump and want
                      help, or are unsure of the proposed replacement product,
                      contact Xylem's customer support according to the contact
                      information below“
                    </li>
                  </ul>

                  <ul style={ulStyle}>
                    <b>4. Country-specific contact details</b>
                    <br></br> Provide the local email and telephone number for
                    your country, that customers can use to contact when they
                    are unsure about a suggested replacement pump:{" "}
                    <li style={ulStyle}>a. 📧:"email here"</li>{" "}
                    <li style={ulStyle}> b. ☎️:"number here"</li>
                  </ul>
                  <p>
                    Contact{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    for technical questions.
                  </p>
                  <p>
                    Contact{" "}
                    <a href="mailto: Roger.Widnersson@xylem.com">
                      Roger.Widnersson@xylem.com
                    </a>{" "}
                    for pump related questions.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br></br>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b>New Assortment</b>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ padding: "10px" }}>
                  <h4>How to proceed?</h4>
                  <ul style={ulStyle}>
                    1. Download the excel file{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={AssortmentExcelFile}
                      download
                    >
                      {" "}
                      Link to excel file{" "}
                    </a>
                    <br></br>
                    2. Insert the new assortment in the excel file template
                    structure, you see an example tab in the excel file how it
                    is intended and follow the instructions noted in the
                    separate instructions tab.
                    <br></br>
                    3. When the file is finalized, it needs to be sent to the
                    Swedish sales team for further validation and implementation
                    in the XylemPump.id database.
                    <br></br>
                    4. The expected time for new entries in the database will
                    depend on the number of rows that needs to be validated.
                    <ul style={ulStyle}>
                      - For single rows we can manually validate and insert the
                      replacements by the Swedish sales team in the admin tool
                      as soon as possible.
                    </ul>
                    <ul style={ulStyle}>
                      - New Brands or type of pumps and batch inputs of more
                      than 10 pumps will be validated first by the Swedish sales
                      team and then transferred to 2550 for development and
                      adaptions if needed.
                    </ul>
                  </ul>
                  <br></br>
                  <p>
                    For new users we recommend that you contact the Swedish
                    sales team to get an instruction of the mind set for the
                    replacement data.
                  </p>
                  <p>
                    The Swedish team contacts are:
                    <br></br>
                    <a href="mailto: Roger.Widnersson@xylem.com">
                      Roger.Widnersson@xylem.com
                    </a>{" "}
                    <br></br>
                    and
                    <br></br>
                    <a href="mailto: Emil.Wallander@xylem.com">
                      Emil.Wallander@xylem.com
                    </a>{" "}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br></br>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b>Add New Pumps</b>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ padding: "10px" }}>
                  <p>
                    This procedure is used to request addition of new pump
                    records to database using an Excel template from 2550
                    development team.
                    <br></br>
                    Main use case for the template is to add new pump records to
                    the database. Note that adding replacement relationships for
                    those pumps is another procedure.
                  </p>
                  <ul style={ulStyle}>
                    1. Download and examine the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={AddPumpTemplateUserGuide}
                      download
                    >
                      user guide
                    </a>{" "}
                    for the Excel template.
                    <br></br>
                    2. Download the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={AddPumpTemplate}
                      download
                    >
                      Excel template.
                    </a>
                    <br></br>
                    3. Fill the template with necessary data for each new pump
                    record that is desired to be added to database.
                    <br></br>
                    4. Send an email to{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    about the request, and please attach the prepared Excel
                    document.
                    <br></br>
                  </ul>
                  <br></br>
                  <p>
                    Contact{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    for technical questions.
                  </p>
                  <p>
                    Contact{" "}
                    <a href="mailto: Roger.Widnersson@xylem.com">
                      Roger.Widnersson@xylem.com
                    </a>{" "}
                    for pump related questions.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br></br>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b>Add Replacement Relationships</b>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ padding: "10px" }}>
                  <p>
                    This procedure is used to request extension of the
                    replacement guide with new replacement relationships between
                    pumps using an Excel template from 2550 development team.
                    <br></br>
                    Main use case for the template occurs during adding new
                    pumps to the database.
                    <br></br>
                    First step is to create records for the new pumps. This can
                    be done with the procedure for adding new pump records.
                    After the pump records are created, this template can be
                    used to secure replacement relationships between pumps. In
                    essence, this file is used to programmatically describe that
                    which pump can be replaced by which other pumps, to extend
                    the existing replacement relations in the database.
                  </p>
                  <ul style={ulStyle}>
                    1. Download and examine the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={AddReplacementUserGuide}
                      download
                    >
                      user guide
                    </a>{" "}
                    for the Excel template.
                    <br></br>
                    2. Download the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={AddReplacementTemplate}
                      download
                    >
                      Excel template.
                    </a>
                    <br></br>
                    3. Fill the template with necessary data for each new
                    replacement relationship that is desired to be added to
                    database.
                    <br></br>
                    4. Send an email to{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    about the request, and please attach the prepared Excel
                    document.
                    <br></br>
                  </ul>
                  <br></br>
                  <p>
                    Contact{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    for technical questions.
                  </p>
                  <p>
                    Contact{" "}
                    <a href="mailto: Roger.Widnersson@xylem.com">
                      Roger.Widnersson@xylem.com
                    </a>{" "}
                    for pump related questions.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <br></br>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <b>Add or Modify Country Specific Pump Identifiers</b>
              </Accordion.Header>
              <Accordion.Body>
                <div style={{ padding: "10px" }}>
                  <p>
                    This procedure is used to request modification of country
                    specific pump identifiers associated with pumps using an
                    Excel template from 2550 development team.<br></br>
                    For example, pumps can be associated with ‘rsk’ numbers for
                    Sweden, or ‘’nrf’ numbers for Norway, ‘lvi’ numbers for
                    Finland etc.<br></br>
                  </p>
                  <ul style={ulStyle}>
                    1. Download and examine the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={UpdateCsiUserGuide}
                      download
                    >
                      user guide
                    </a>{" "}
                    for the Excel template.
                    <br></br>
                    2. Download the{" "}
                    <a
                      style={{ fontWeight: 600 }}
                      href={UpdateCsiTemplate}
                      download
                    >
                      Excel template.
                    </a>
                    <br></br>
                    3. Fill the template with necessary data for each pump
                    record for which the country specific pump identifier
                    information is to be modified.
                    <br></br>
                    4. Send an email to{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    about the request, and please attach the prepared Excel
                    document.
                    <br></br>
                  </ul>
                  <br></br>
                  <p>
                    Contact{" "}
                    <a href="mailto: berkay.oymak@2550.engineering">
                      berkay.oymak@2550.engineering
                    </a>{" "}
                    for technical questions.
                  </p>
                  <p>
                    Contact{" "}
                    <a href="mailto: Roger.Widnersson@xylem.com">
                      Roger.Widnersson@xylem.com
                    </a>{" "}
                    for pump related questions.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </section>
      </div>
    </div>
  );
};
