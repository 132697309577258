import React, { useState, useEffect } from "react";
import {
  MetadataState,
  fetchAllComments,
  logOut,
  showStatusModal,
} from "../../NewStore";
import { NewComment } from "../New/NewComment";
import { SaveModal } from "../SaveModal";
import { DeleteModal } from "../DeleteModal";
import { PumpListModal } from "../PumpListModal";

import { useSnapshot } from "valtio";

export const Comments = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const [newComment, setNewComment] = useState(false);
  const [save, setSave] = useState(false);
  const [del, setDel] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState({});
  const [commentToEdit, setCommentToEdit] = useState({});
  const [updatedComment, setUpdatedComment] = useState({});
  const [pumpList, setPumpList] = useState([]);
  const [pumpListModal, setPumpListModal] = useState(false);

  const uniqueProperty = (arr, property) => {
    return [...new Set(arr.map((obj) => obj[property]))];
  };

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };
  const toggleDelModal = () => {
    setDel(!del);
  };

  const toggleCommentModal = () => {
    setNewComment(!newComment);
  };

  const togglePumpListModalModal = () => {
    setPumpListModal(!pumpListModal);
  };

  useEffect(() => {
    sortComments();
  });
  const sortComments = () => {
    let sorted = MetadataState.comments.sort((a, b) =>
      a.key > b.key ? 1 : b.key > a.key ? -1 : 0
    );
    return uniqueProperty(sorted, "key");
  };

  const updateComment = () => {
    fetch(
      `/api/admin/comments/${commentToEdit.key}?language=${commentToEdit.language}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ text: updatedComment.text }),
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        fetchAllComments();
        setSave(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteComment = () => {
    fetch(
      `/api/admin/comments/${commentToDelete.key}?language=${commentToDelete.language}`,
      {
        method: "DELETE",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();

        return response.json();
      })
      .then((data) => {
        setPumpList(data);
        if (data.length > 0) {
          setPumpListModal(true);
        }
        fetchAllComments();
        setDel(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    //
  };

  return (
    <div className="Wrapper">
      <section>
        <h3>Comments</h3>
        <div className="commentSection">
          <button className="addButton" onClick={() => setNewComment(true)}>
            Create new comment
          </button>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "26rem" }}>Key</td>
                <td style={{ width: "6rem" }}>Language</td>
                <td style={{ width: "16rem" }}>Comment</td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              {MetadataSnap.comments.map(({ key, language, text }, idx) => {
                return (
                  <tr key={"comment-" + key + "-" + language}>
                    <td>
                      <input
                        type="text"
                        defaultValue={key}
                        readOnly
                        onChange={(event) =>
                          setUpdatedComment({
                            ...updatedComment,
                            key: event.target.value,
                          })
                        }
                      />
                    </td>
                    <td className="languageInput">
                      <input
                        className="languageInput"
                        readOnly
                        type="text"
                        defaultValue={language}
                        onChange={(event) =>
                          setUpdatedComment({
                            ...updatedComment,
                            language: event.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <textarea
                        type="text"
                        defaultValue={text}
                        onChange={(event) =>
                          setUpdatedComment({
                            ...updatedComment,
                            text: event.target.value,
                          })
                        }
                      />
                    </td>
                    <td>
                      <div id="comments" key={idx}>
                        <button
                          className="addButton"
                          style={{ margin: "0rem" }}
                          onClick={() => {
                            setSave(true);
                            setCommentToEdit({ key, language, text });
                          }}
                        >
                          Save
                        </button>
                        <button
                          style={{ margin: "0 0 0 0.1rem" }}
                          className="deleteButton"
                          onClick={() => {
                            setDel(true);
                            setCommentToDelete({ key, language });
                          }}
                        >
                          Delete comment
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
      {pumpListModal && (
        <PumpListModal
          toggleModal={togglePumpListModalModal}
          pumpList={pumpList}
        />
      )}
      {newComment && <NewComment toggleModal={toggleCommentModal} />}
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={updateComment} />
      )}
      {del && (
        <DeleteModal toggleModal={toggleDelModal} deleteState={deleteComment} />
      )}
    </div>
  );
};
