import React, { useState } from "react";
import { SearchReplacement } from "../SearchReplacement";
import { ImCross } from "react-icons/im";

import { SaveModal } from "../SaveModal";
import { ReplacementTypes } from "../Selectors/ReplacementTypes";
import {
  MetadataState,
  UpdatedState,
  SelectionState,
  fetchPumpData,
  logOut,
  showStatusModal,
} from "../../NewStore";

import { useSnapshot } from "valtio";
import produce from "immer";

const uniqueProperty = (arr, property) => {
  return [...new Set(arr.map((obj) => obj[property]))];
};

export const NewReplacementPump = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const UpdatedSnap = useSnapshot(UpdatedState);
  const SelectionSnap = useSnapshot(SelectionState);
  const [errMsg, setErrMsg] = useState(null);
  const [errclass, setErrClass] = useState(null);

  const newReplacement = UpdatedSnap.newReplacementPump;
  const [newRep, setNewRep] = useState({ ...newReplacement });

  const [save, setSave] = useState(false);
  const saveToState = () => {
    setNewRep(
      produce(newRep, (draftState) => {
        if (!draftState.orininalPumpId)
          draftState.orininalPumpId = SelectionSnap.selectedPump;
      })
    );
  };

  const validateForm = () => {
    if (
      !(
        typeof newRep.replacementType === "string" &&
        typeof newRep.name === "string"
      )
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }
    return (
      typeof newRep.replacementType === "string" &&
      typeof newRep.name === "string"
    );
  };

  const addNewReplacement = () => {
    fetch(
      `/api/admin/pumps/${newRep.orininalPumpId}/addReplacement/${newRep.id}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: sessionStorage.getItem("token"),
        },
        body: JSON.stringify(newRep),
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        setSave(!save);
        props.toggleModal();
        fetchPumpData();
        UpdatedState.newReplacementPump = {};
      })
      .catch((error) => console.log(error));
  };

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  const addMoreComment = () => {
    setNewRep(
      produce(newRep, (draftState) => {
        if (!draftState.comments) draftState.comments = [];

        draftState.comments.push("");
      })
    );
  };

  const setComment = (index) => {
    return (event) => {
      setNewRep(
        produce(newRep, (draftState) => {
          draftState.comments[index] = event.target.value;
        })
      );
    };
  };

  const deleteComment = (index) => {
    return () => {
      setNewRep(
        produce(newRep, (draftState) => {
          draftState.comments.splice(index, 1);
        })
      );
    };
  };

  const addMoreAdapters = () => {
    setNewRep(
      produce(newRep, (draftState) => {
        if (!draftState.adapters) draftState.adapters = [];

        draftState.adapters.push({
          quantity: 0,
          id: "",
        });
      })
    );
  };

  const setAdapter = (index) => {
    return (event) => {
      setNewRep(
        produce(newRep, (draftState) => {
          draftState.adapters[index].id = event.target.value;
        })
      );
    };
  };

  const setQuantity = (index, adapterId) => {
    return (event) => {
      setNewRep(
        produce(newRep, (draftState) => {
          draftState.adapters[index].quantity = parseInt(event.target.value);
        })
      );
    };
  };

  const deleteAdapter = (index) => {
    return () => {
      setNewRep(
        produce(newRep, (draftState) => {
          draftState.adapters.splice(index, 1);
        })
      );
    };
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setNewRep({ ...newRep, [name]: value });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h2>Add new replacement pump</h2>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}

        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <SearchReplacement newRep={newRep} setNewRep={setNewRep} />
        <table style={{ marginTop: "5rem" }}>
          <tbody>
            <tr>
              <td>Selected replacement:</td>
              <td style={errclass}>
                <b>{UpdatedSnap.newReplacementPump.name}</b>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="pumpType">Select replacement type</label>
              </td>
              <td style={errclass}>
                <ReplacementTypes
                  onChange={onChange}
                  newRep={newRep}
                  setNewRep={setNewRep}
                />
              </td>
            </tr>
            {newRep.comments?.map((comment, idx) => {
              return (
                <tr key={"Comment_" + idx}>
                  <td>
                    <label htmlFor="repComments">Comment:</label>
                  </td>
                  <td>
                    <select
                      id="repComments"
                      onChange={setComment(idx)}
                      value={comment}
                    >
                      {uniqueProperty(MetadataState.comments, "key").map(
                        (key, idx) => {
                          return (
                            <option value={key} key={idx}>
                              {key}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </td>
                  <td>
                    <button
                      className="deleteButton"
                      style={{ margin: "0" }}
                      onClick={deleteComment(idx)}
                    >
                      Delete comment
                    </button>
                  </td>
                </tr>
              );
            })}
            {newRep.adapters && (
              <>
                {Object.values(newRep.adapters)?.map(
                  ({ id, quantity }, idx) => {
                    return (
                      <React.Fragment key={"Adapters_" + idx}>
                        <tr>
                          <td>
                            <label htmlFor="adaptersSelect">Adapter:</label>
                          </td>
                          <td>
                            <select
                              name="adapter"
                              onChange={setAdapter(idx)}
                              value={id}
                            >
                              {MetadataSnap.allAdapters.map(
                                ({ id, description }, idx) => {
                                  return (
                                    <option
                                      value={id}
                                      key={"allAdapters_" + idx}
                                    >
                                      {id} - {description}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </td>
                          <td>
                            <button
                              className="deleteButton"
                              style={{ margin: "0" }}
                              onClick={deleteAdapter(idx)}
                            >
                              Delete adapter
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="numAdapters">
                              Quantity of adapters:
                            </label>
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={quantity}
                              name="quantity"
                              onChange={setQuantity(idx, id)}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                )}
              </>
            )}
          </tbody>
        </table>
        <div className="addMore">
          <button className="addButton" onClick={() => addMoreComment()}>
            Add more comments
          </button>
          <button
            className="addButton"
            style={{ marginLeft: "1rem" }}
            onClick={() => addMoreAdapters()}
          >
            Add more adapters
          </button>
        </div>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
              saveToState();
            } else {
              setErrMsg("Please fill up all required fields marked red");
            }
            /*  saveToState();
            setSave(true); */
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={addNewReplacement} />
      )}
    </div>
  );
};
