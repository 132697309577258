import React from "react";
import { BsExclamation } from "react-icons/bs";
import { ImCross } from "react-icons/im";

export const PumpListModal = (props) => {
  const pumpList = props.pumpList;

  return (
    <div className="ModalWrapper pumpListModal">
      <section className="Modal scrollable">
        <h3>
          <BsExclamation className="warningSign" />
          Warning
        </h3>
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <h5>
          This item cannot be deleted because it is being used by the pumps in
          the list below.
        </h5>
        <h5>
          Deleting this item while used would cause the replacement guide to
          crash.
        </h5>
        <h5 style={{ marginBottom: "3rem" }}>
          To be able to delete the item, first remove it from all the pumps.
        </h5>
        {pumpList.map(({ name, originalId }, idx) => {
          return (
            <div key={"pumpList" + idx}>
              <p>
                {name} - {originalId}
              </p>
            </div>
          );
        })}
      </section>
    </div>
  );
};
