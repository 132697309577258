import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { SaveModal } from "../SaveModal";
import { logOut, showStatusModal } from "../../NewStore";
const passwordLengthRequirement = 6;

export const EditPassword = (props) => {
  const [save, setSave] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  // const [passVerification, setPassVerification] = useState(null);
  const [msg, setmsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errclass, setErrClass] = useState(null);

  const { id } = props;
  // const loginData = useSnapshot(LogInState);
  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  // const matchPreviousPassword = (e) => {
  //     fetch(`/api/users/verifypass`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": sessionStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({ password: e.target.value, id: loginData.id }),
  //       mode: "cors",
  //     })
  //     .then(response => response.json())
  //     .then(data => setPassVerification(data))
  //     .catch(err => console.log(err));
  // }

  const handleInputChange = (event) => {
    setNewPassword(event.target.value);
  };

  const matchPasswords = (event) => {
    return newPassword !== "" && newPassword === event.target.value
      ? setmsg("Paswords matches")
      : setmsg("Password doesn't match");
  };

  const validateForm = () => {
    if (
      typeof newPassword === "" ||
      document.getElementById("reenterPassword").value === newPassword
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }

    return (
      typeof newPassword === "string" &&
      newPassword >= passwordLengthRequirement
    );
  };

  const resetPassword = () => {
    fetch(`/api/admin/users/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify({ password: newPassword, id: id }),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Edit Password</h3>
        <span>{msg}</span>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}

        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div className="userDetails">
          {/* {loginData.role !== "Admin" && (
            <>
              <label htmlFor="Password">Old password</label>
              <input type="password" id="oldpassword" onChange={matchPreviousPassword} className="editInput" />
              { passVerification === true ? <p>Password Verified</p> : <p>Wrong Password</p> }
            </>
          )} */}

          <label htmlFor="Password">New password</label>
          <input
            type="password"
            id="password"
            className="editInput"
            onChange={handleInputChange}
            style={errclass}
          />
          <label htmlFor="reenterPassword">Reenter new password</label>
          <input
            type="password"
            id="reenterPassword"
            className="editInput"
            onChange={matchPasswords}
            style={errclass}
          />
        </div>
        <button
          className="addButton"
          onClick={() => {
            if (
              document.getElementById("reenterPassword").value === newPassword
            ) {
              if (validateForm()) {
                setSave(true);
              }
            } else {
              setErrClass({ border: "#c4014C 2px solid" });
              setErrMsg("Please fill up all fields");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={resetPassword} />
      )}
    </div>
  );
};
