import React from "react";
import { FileState } from "../../NewStore";
import { useSnapshot } from "valtio";

export const DatasheetSelector = (props) => {
  const Files = useSnapshot(FileState);

  const handleChange = (event) => {
    if (props.showDeleteDatasheet) {
      props.showDeleteDatasheet();
    }
    if (props.setPumpData) {
      props.setPumpData({ ...props.pumpData, datasheet: event.target?.value });
    }
    if (props.setNewPump) {
      props.setNewPump({
        ...props.newPump,
        datasheet: event.target.value,
      });
    }
    if (props.setSelectedFiles) {
      props.setSelectedFiles({
        ...props.selectedFiles,
        datasheet: event.target.value,
      });
    }
  };
  /*  const getPdf = () => {
    let pdf = document.getElementById("datasheetSelect").value;

    fetch(`/api/admin/file/${pdf}`, {
      headers:{
        "Authorization": sessionStorage.getItem("token"),
      }
    })
      .then((response) => response.blob())
      .then((data) => {
        setPdf(URL.createObjectURL(data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }; */

  return (
    <select
      id="datasheetSelect"
      name="datasheet"
      className="changeBorder"
      onChange={(event) => {
        handleChange(event);
        // getPdf();
      }}
      value={
        props.pumpData?.datasheet ||
        props.setSelectedFiles?.datasheet ||
        props.newPump?.datasheet ||
        ""
      }
      style={props.style}
    >
      <option hidden>
        {props.datasheet || props.fileDatasheet || "Select Datasheet"}
      </option>
      {Files.allFiles.map(({ name, language, type }, idx) => {
        if (type === "application/pdf") {
          return (
            <option value={name} key={idx}>
              {name}
            </option>
          );
        } else {
          return null;
        }
      })}
    </select>
  );
};
