import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import "../App.sass";
import { LogInState } from "../NewStore";
import { Redirect } from "react-router-dom";
import { useSnapshot } from "valtio";

const currentPath = window.location.pathname;

export const Login = () => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errMsg, setErrMsg] = useState(null);

  const loginStatus = useSnapshot(LogInState);

  const logInUser = async (credentials) => {
    return fetch("/api/admin/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .catch((err) => {
        console.log(err.response);
      });
  };

  const logInClicked = async () => {
    // alert( username.length)
    if (typeof username !== "undefined" || typeof password !== "undefined") {
      if (username.length === 0) {
        setErrMsg("Please write your credentials");
      } else {
        const token = await logInUser({ email: username, password });
        if (typeof token.token === "string") {
          sessionStorage.setItem("token", token.token);
          LogInState.status = true;
          const decodedData = jwtDecode(sessionStorage.getItem("token"));
          LogInState.status = true;
          LogInState.email = decodedData.email;
          LogInState.role = decodedData.role;
          LogInState.id = decodedData.id;
        } else {
          setErrMsg(
            "credentials mismatch, please check your username or password"
          );
        }
      }
    } else {
      setErrMsg("Please write your credentials");
    }
  };
  if (loginStatus.status === true && currentPath !== "/Login") {
    return <Redirect to={currentPath} />;
  } else if (loginStatus.status === true && currentPath === "/Login") {
    return <Redirect to="/Users" />;
  }
  let showErrMsg = "";
  if (errMsg !== null) {
    showErrMsg = <p style={{ color: "#c4014C" }}>{errMsg}</p>;
  }

  return (
    <div className="ModalWrapper">
      <div className="loginWrapper">
        <div className="loginContainer">
          <h1>Xylem's Admin Tool</h1>
          {showErrMsg}
          <div className="inputDiv">
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              onChange={(e) => setUserName(e.target.value)}
              type="text"
            />
          </div>
          <div className="inputDiv">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  logInClicked();
                }
              }}
            />
          </div>
          <p className="lostPassword">Forgot your password?</p>
          <button onClick={logInClicked}>Log in</button>
        </div>
      </div>
    </div>
  );
};
