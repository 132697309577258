import React, { useState } from "react";

import { ImCross } from "react-icons/im";

import { SaveModal } from "../SaveModal";
import { fetchAllComments, logOut, showStatusModal } from "../../NewStore";
import { LanguageSelect } from "../Selectors/LanguageSelect";

export const NewComment = (props) => {
  const [save, setSave] = useState(false);
  const [comment, setComment] = useState({});

  const [errMsg, setErrMsg] = useState(null);
  const [errclass, setErrClass] = useState(null);

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  const validateForm = () => {
    if (
      !(
        typeof comment.key === "string" &&
        typeof comment.language === "string" &&
        typeof comment.text === "string"
      )
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }
    return (
      typeof comment.key === "string" &&
      typeof comment.language === "string" &&
      typeof comment.text === "string"
    );
  };

  // const saveToState = () => {
  //   UpdatedState.newComment = comment;
  //   setSave(!save);
  //   console.log(UpdatedState.newComment);
  //   props.toggleModal();
  // };

  const createComment = () => {
    fetch(`/api/admin/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(comment),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        fetchAllComments();
        setSave(!save);
        props.toggleModal();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Create new comment</h3>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}
        <ImCross className="crossIcon" onClick={props.toggleModal} />

        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="newKey">
                  Key
                  <input
                    type="text"
                    id="newKey"
                    placeholder="e.g. noRebuild"
                    onChange={(event) => {
                      setComment({ ...comment, key: event.target.value });
                    }}
                    style={errclass}
                  />
                </label>
              </td>
              <td>
                <label htmlFor="language">
                  Language
                  <LanguageSelect
                    comment={comment}
                    setComment={setComment}
                    defaultValue={"Select language"}
                    errclass={errclass}
                  />
                </label>
              </td>
              <td>
                <label htmlFor="comment">
                  Comment
                  <input
                    type="text"
                    id="comment"
                    placeholder="Comment"
                    onChange={(event) => {
                      setComment({ ...comment, text: event.target.value });
                    }}
                    style={errclass}
                  />
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
            } else {
              setErrMsg("Please fill up all required fields marked red");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={createComment} />
      )}
    </div>
  );
};
