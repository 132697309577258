import React, { useState } from "react";
import { FileState } from "../../NewStore";
import { useSnapshot } from "valtio";

export const ImageSelector = (props) => {
  const File = useSnapshot(FileState);
  const [img, setImg] = useState();

  const handleChange = (event) => {
    let image = document.getElementById("imgSelector").value;
    if (props.showDeleteImg) {
      props.showDeleteImg();
    }
    if (props.setPumpData) {
      props.setPumpData({ ...props.pumpData, image: event.target.value });
    }
    if (props.setNewPump) {
      props.setNewPump({
        ...props.newPump,
        image: event.target.value,
      });
      props.setUploadImage(undefined);
    }
    if (props.setSelectedFiles) {
      props.setSelectedFiles({
        ...props.selectedFiles,
        image: event.target.value,
      });
    }
    if (props.setNewAdapter) {
      props.setNewAdapter({
        ...props.newAdapter,
        image: event.target.value,
      });
    }
    if (props.setUpdAdapter) {
      props.setUpdAdapter({
        ...props.updAdapter,
        image: event.target.value,
      });
    }
    if (props.setPreview) {
      props.setPreview(image);
      props.setImageName({
        ...props.imageName,
        image: event.target.value,
      });
    }
    if (props.setAccessory) {
      props.setAccessory({ ...props.accessory, image: event.target.value });
    }
    if (props.setUpdatedAccessory) {
      props.setUpdatedAccessory({
        ...props.updatedAccessory,
        image: event.target.value,
      });
    }
  };

  /* const getImage = () => {
    let image = document.getElementById("imgSelector").value;

    fetch(`/api/admin/file/${image}`)
      .then((response) => response.blob())
      .then((data) => {
        //setImg(data);
        //document.getElementById("test").src = URL.createObjectURL(data);
        FileState.imagePreview = URL.createObjectURL(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }; */

  return (
    <>
      <select
        id="imgSelector"
        className="changeBorder"
        onChange={(event) => {
          handleChange(event);
          // getImage();
        }}
        value={
          props.pumpData?.image ||
          props.newPump?.image ||
          props.imageName?.image ||
          props.accessory?.image ||
          props.updatedAccessory?.image ||
          ""
        }
        style={props.style}
      >
        <option hidden>{props.fileImage || "Select image"}</option>
        {File.allFiles.map(({ name, language, type }, idx) => {
          if (type === "image/png") {
            return (
              <option
                value={name}
                key={idx}
                /*  onChange={() => setDeleteImage(name)} */
              >
                {name}
              </option>
            );
          } else {
            return null;
          }
        })}
      </select>
      {/*  <img id="test" src={img} alt="" /> */}
    </>
  );
};
