import React, { useState } from "react";
import { useSnapshot } from "valtio";

import { fetchAllAccessories, MetadataState } from "../../NewStore";
import { DeleteModal } from "../DeleteModal";
import { NewAccessory } from "../New/NewAccessory";
import { EditAccessory } from "../Edit/EditAccessory";
import { logOut, showStatusModal } from "../../NewStore";
import { PumpListModal } from "../PumpListModal";

export const Accessories = () => {
  const MetadataSnap = useSnapshot(MetadataState);
  const allAccessories = MetadataSnap.allAccessories;
  const [save, setSave] = useState(false);
  const [del, setDel] = useState(false);
  const [newAccessory, setNewAccessory] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState(false);
  const [editAccessory, setEditAccessory] = useState(false);
  const [accessoryToDelete, setAccessoryToDelete] = useState(false);
  const [pumpList, setPumpList] = useState([]);
  const [pumpListModal, setPumpListModal] = useState(false);

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };
  const toggleDelModal = () => {
    setDel(!del);
  };

  const toggleEditAccessory = () => {
    setEditAccessory(!editAccessory);
  };
  const toggleAccessoryModal = () => {
    setNewAccessory(!newAccessory);
  };

  const togglePumpListModalModal = () => {
    setPumpListModal(!pumpListModal);
  };

  const openEditAccessory = (accessory) => {
    setEditAccessory(true);
    setSelectedAccessory(accessory);
  };

  const deleteAccessory = () => {
    fetch(`/api/admin/accessory/${accessoryToDelete}`, {
      method: "DELETE",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();

        return response.json();
      })
      .then((response) => {
        setPumpList(response);
        setDel(false);
        fetchAllAccessories();
        if (response.length > 0) {
          setPumpListModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="Wrapper">
      <section className="accessories">
        <h3>Accessories</h3>
        <div className="accessoriesSection">
          <button className="addButton" onClick={() => setNewAccessory(true)}>
            Create new accessory
          </button>
          <table className="accessoriesHeaderName">
            <tbody>
              <tr>
                {/* <td style={{ width: "4rem" }}>ID</td> */}
                <td>Product name</td>
                <td>Product</td>
                <td>Group word</td>

                <td style={{ width: "6rem" }}>Rsk</td>

                {/* <td>Group word</td>
              <td style={{ width: "4rem" }}>Order</td>
              <td style={{ width: "5rem" }}>Suborder</td>
              <td>Description</td>
              <td>Image</td> */}
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              {allAccessories.map((accessories, idx) => {
                const id = accessories.id;
                const product = accessories.productName;
                const order = accessories.order;
                const subOrder = accessories.subOrder;
                const rsk = accessories.rsk;
                const groupWord = accessories.groupWord;
                const description = accessories.description;
                const name = accessories.name;
                const image = accessories.image;

                return (
                  <tr key={"accessorie_" + idx}>
                    <td>
                      <input type="text" value={product} readOnly />
                    </td>
                    <td>
                      <input type="text" value={name} readOnly />
                    </td>
                    <td>
                      <input type="text" value={groupWord} readOnly />
                    </td>
                    <td>
                      <input
                        type="text"
                        style={{ width: "5rem" }}
                        value={rsk}
                        readOnly
                      />
                    </td>
                    <td>
                      <div
                        id="comments"
                        key={idx}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <button
                          className="addButton"
                          style={{ margin: "0rem" }}
                          onClick={() => {
                            openEditAccessory(accessories);
                          }}
                        >
                          Edit Accessory
                        </button>
                        <button
                          style={{ margin: "0 0 0 0.1rem" }}
                          className="deleteButton"
                          onClick={() => {
                            setAccessoryToDelete(id);
                            setDel(true);
                            // deleteAccessory(id);
                          }}
                        >
                          Delete Accessory
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
      {pumpListModal && (
        <PumpListModal
          toggleModal={togglePumpListModalModal}
          pumpList={pumpList}
        />
      )}
      {newAccessory && <NewAccessory toggleModal={toggleAccessoryModal} />}
      {editAccessory && (
        <EditAccessory
          selectedAccessory={selectedAccessory}
          toggleModal={toggleEditAccessory}
        />
      )}

      {/* {save && <SaveModal toggleModal={toggleModal} />} */}
      {del && (
        <DeleteModal
          toggleModal={toggleDelModal}
          deleteState={deleteAccessory}
        />
      )}
    </div>
  );
};
