import React, { useState } from "react";
import { useSnapshot } from "valtio";

import {
  MetadataState,
  fetchAllAdapters,
  logOut,
  showStatusModal,
} from "../../NewStore";
import { ImageSelector } from "../Selectors/ImageSelector";
import { ImCross } from "react-icons/im";

import { SaveModal } from "../SaveModal";

import produce from "immer";

export const EditAdapter = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const allAdapters = MetadataSnap.allAdapters;

  let adapter = allAdapters[props.adapter];
  const [updAdapter, setUpdAdapter] = useState({ ...adapter });
  const [save, setSave] = useState(false);
  let translations = adapter.translations || {
    en: "",
    sv: "",
    de: "",
    nl: "",
  };
  const updateAdapter = () => {
    fetch(`/api/admin/adapters/${adapter.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(updAdapter),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllAdapters();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const keyOrDescription = (event) => {
    setUpdAdapter({
      ...updAdapter,
      description: event.target.value,
    });
  };

  const toggleModal = () => {
    setSave(!save);
  };

  const setTranslation = (k) => {
    return (event) => {
      setUpdAdapter(
        produce(updAdapter, (draftState) => {
          if (!draftState.translations) draftState.translations = {};

          draftState.translations[k] = event.target.value;
        })
      );
    };
  };
  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Edit adapter</h3>
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <table>
          <tbody>
            <tr>
              <td>ID</td>
              <td>
                <input
                  type="text"
                  defaultValue={adapter.id}
                  onChange={(event) => {
                    setUpdAdapter({
                      ...updAdapter,
                      id: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>RSK</td>
              <td>
                <input
                  type="text"
                  defaultValue={adapter.rsk}
                  onChange={(event) => {
                    setUpdAdapter({
                      ...updAdapter,
                      rsk: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>{updAdapter.translated ? "Key" : "Description"}</td>
              <td>
                <input
                  type="text"
                  id="becomeKey"
                  defaultValue={adapter.description}
                  onChange={(event) => {
                    keyOrDescription(event);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Image</td>
              <td>
                <ImageSelector
                  setUpdAdapter={setUpdAdapter}
                  updAdapter={updAdapter}
                  fileImage={updAdapter?.image}
                />
              </td>
            </tr>
            <tr>
              <td>Translation ( &#x2713; if yes)</td>
              <td>
                <input
                  type="checkbox"
                  id="translate"
                  checked={updAdapter.translated ? true : false}
                  onChange={() =>
                    setUpdAdapter({
                      ...updAdapter,
                      translated: updAdapter.translated ? 0 : 1,
                    })
                  }
                />
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            {updAdapter.translated ? (
              <>
                <tr>
                  <td>Language</td>
                  <td>Description</td>
                </tr>
                {Object.entries(translations)?.map(([k, v], idx) => (
                  <tr key={idx}>
                    <td>
                      <input type="text" readOnly value={k} />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="TranslationValue"
                        defaultValue={v}
                        onChange={setTranslation(k)}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ) : null}
          </tbody>
        </table>
        <button className="addButton" onClick={() => setSave(true)}>
          Save changes
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={updateAdapter} />
      )}
    </div>
  );
};
