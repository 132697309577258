import React, { useState } from "react";
import { ImCross } from "react-icons/im";

import {
  MetadataState,
  SelectionState,
  fetchPumpData,
  logOut,
  showStatusModal,
} from "../../NewStore";
import { ReplacementTypes } from "../Selectors/ReplacementTypes";

import { useSnapshot } from "valtio";

import produce from "immer";
import { setAutoFreeze } from "immer";
import { SaveModal } from "../SaveModal";

// Returns all unique values for a selected property from an array of objects
const uniqueProperty = (arr, property) => {
  return [...new Set(arr.map((obj) => obj[property]))];
};

export const EditReplacementPump = (props) => {
  const replacement = props.repProps;
  const MetadataSnap = useSnapshot(MetadataState);
  const SelectionSnap = useSnapshot(SelectionState);
  const [replacementData, setReplacementData] = useState({ ...replacement });
  const [save, setSave] = useState(false);

  //What does this actually do??
  setAutoFreeze(false);

  const saveToState = () => {
    setReplacementData(
      produce(replacementData, (draftState) => {
        if (!draftState.orininalPumpId)
          draftState.orininalPumpId = SelectionSnap.selectedPump;
      })
    );
  };

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  const addMoreComment = () => {
    setReplacementData(
      produce(replacementData, (draftState) => {
        if (!draftState.comments) draftState.comments = [];

        draftState.comments.push("");
      })
    );
  };

  const setComment = (index) => {
    return (event) => {
      setReplacementData(
        produce(replacementData, (draftState) => {
          draftState.comments[index] = event.target.value;
        })
      );
    };
  };

  const deleteComment = (index) => {
    return () => {
      setReplacementData(
        produce(replacementData, (draftState) => {
          draftState.comments.splice(index, 1);
        })
      );
    };
  };

  const addMoreAdapters = () => {
    setReplacementData(
      produce(replacementData, (draftState) => {
        if (!draftState.adapters) draftState.adapters = [];

        draftState.adapters.push({
          quantity: 0,
          id: "",
        });
      })
    );
  };

  const setAdapter = (index) => {
    return (event) => {
      setReplacementData(
        produce(replacementData, (draftState) => {
          draftState.adapters[index].id = event.target.value;
        })
      );
    };
  };

  const deleteAdapter = (index) => {
    return () => {
      setReplacementData(
        produce(replacementData, (draftState) => {
          draftState.adapters.splice(index, 1);
        })
      );
    };
  };

  const setQuantity = (index, adapterId) => {
    return (event) => {
      setReplacementData(
        produce(replacementData, (draftState) => {
          draftState.adapters[index].quantity = parseInt(event.target.value);
        })
      );
    };
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setReplacementData({ ...replacementData, [name]: value });
  };

  const updateReplacementPump = () => {
    saveToState();
    fetch(
      `/api/admin/pumps/${SelectionSnap.selectedPump}/editReplacement/${replacementData.replacementId}`,
      {
        method: "PUT",
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-type": "application/json",
        },
        body: JSON.stringify(replacementData),
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        setSave(!save);
        props.toggleModal();
        fetchPumpData();
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Edit replacement pump</h3>
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="repName">Name:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="name"
                  name="name"
                  readOnly
                  value={replacementData.name}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="repType">Replacement type:</label>
              </td>
              <td>
                <ReplacementTypes
                  Type={replacement?.replacementType}
                  updatedReplacement={replacementData}
                  setUpdatedReplacement={setReplacementData}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="markets">Available markets</label>
              </td>
              <td>
                <div className="markets">
                  <div className="marketsDiv">
                    <label htmlFor="italy">Italy</label>
                    <input
                      type="checkbox"
                      id="Germany"
                      className="marketsCheckbox"
                      disabled
                    />
                  </div>
                  <div className="marketsDiv">
                    <label htmlFor="england">England</label>
                    <input
                      type="checkbox"
                      id="England"
                      className="marketsCheckbox"
                      disabled
                    />
                  </div>
                  <div className="marketsDiv">
                    <label htmlFor="sweden">Sweden</label>
                    <input
                      type="checkbox"
                      id="Sweden"
                      className="marketsCheckbox"
                      disabled
                    />
                  </div>
                </div>
              </td>
            </tr>
            {replacementData.comments && (
              <>
                {Object.values(replacementData?.comments)?.map(
                  (comment, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <label htmlFor="repComments">Comment:</label>
                        </td>
                        <td>
                          <select
                            id="repComments"
                            onChange={setComment(idx)}
                            value={comment}
                          >
                            {uniqueProperty(MetadataState.comments, "key").map(
                              (key, idx) => {
                                return (
                                  <option value={key} key={idx}>
                                    {key}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </td>
                        <td>
                          <button
                            className="deleteButton"
                            style={{ margin: "0" }}
                            onClick={deleteComment(idx)}
                          >
                            Delete comment
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </>
            )}
            {replacementData?.adapters && (
              <>
                {Object.values(replacementData.adapters)?.map(
                  ({ id, quantity }, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <tr>
                          <td>
                            <label htmlFor="adaptersSelect">Adapter:</label>
                          </td>
                          <td>
                            <select
                              name="adapter"
                              onChange={setAdapter(idx)}
                              value={id}
                            >
                              {MetadataSnap.allAdapters.map(
                                ({ id, description }, idx) => {
                                  return (
                                    <option value={id} key={idx}>
                                      {id} - {description}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </td>
                          <td>
                            <button
                              className="deleteButton"
                              style={{ margin: "0" }}
                              onClick={deleteAdapter(idx)}
                            >
                              Delete adapter
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="numAdapters">
                              Quantity of adapters:
                            </label>
                          </td>
                          <td>
                            <input
                              type="text"
                              defaultValue={quantity}
                              id="test"
                              name="quantity"
                              onChange={setQuantity(idx, id)}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                )}
              </>
            )}
          </tbody>
        </table>
        <div className="addMore">
          <button className="addButton" onClick={() => addMoreComment()}>
            Add more comments
          </button>
          <button
            className="addButton"
            style={{ marginLeft: "1rem" }}
            onClick={() => addMoreAdapters()}
          >
            Add more adapters
          </button>
        </div>
        <button className="addButton" onClick={() => setSave(true)}>
          Save
        </button>
        {save && (
          <SaveModal
            saveToState={updateReplacementPump}
            toggleModal={toggleModal}
          />
        )}
      </section>
    </div>
  );
};
