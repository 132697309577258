import React, { useState } from "react";

export const DataExport = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const getDataFromDatabase = () => {
    setLoading(true);
    setDisable(true);
    fetch(`/api/admin/pumps/allData`)
      .then((response) => response.blob())
      .then(function (blob) {
        setLoading(false);
        setDisable(false);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "PumpData.xlsx";
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className="Wrapper">
      <section>
        <div className="exportDiv">
          <h3>Data export</h3>
          <button
            disabled={disable}
            className="addButton"
            onClick={(e) => getDataFromDatabase()}
            style={{ width: "30%" }}
          >
            Export pump data from database
          </button>
          {loading && (
            <div className="loading">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
              <p className="loadingText">
                Fetching data from database and creating Excel file
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
