import React, { useState, useEffect } from "react";
import { UserAnalyticsState } from "../../NewStore";
import { useSnapshot } from "valtio";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const Statistics = () => {
  const statistics = useSnapshot(UserAnalyticsState);
  // const usersPerDay = statistics.usersPerDay;
  // const topTenPumps = statistics.topTenPumps;
  // const searches = statistics.searchPerDay;

  const [dateFrom, setDateFrom] = useState(["2021-01-01"]);
  const [dateTo, setDateTo] = useState(["2100-01-01"]);
  const [originals, setOriginals] = useState([]);
  const [replacements, setReplacements] = useState([]);
  const [adapters, setAdapters] = useState([]);
  const [usersPerDay, setUsersPerDay] = useState([]);
  const [searceshPerDay, setSearchesPerDay] = useState([]);
  const [topTenPumps, setTopTenPumps] = useState([]);

  useEffect(() => {
    selectedDates();
  }, []);

  useEffect(() => {
    if (dateFrom === "") setDateFrom(["2021-01-01"]);
    if (dateTo === "") setDateTo(["2100-01-01"]);
  }, [dateFrom, dateTo]);

  const selectedDates = () => {
    fetch(`/api/admin/useranalytics/groupByDate/${dateFrom}/${dateTo}`)
      .then((response) => response.json())
      .then((response) => {
        setUsersPerDay(response);
      });

    fetch(`/api/admin/useranalytics/searches/${dateFrom}/${dateTo}`)
      .then((response) => response.json())
      .then((response) => {
        setSearchesPerDay(response);
      });
    fetch(`/api/admin/useranalytics/topTen/${dateFrom}/${dateTo}`)
      .then((response) => response.json())
      .then((response) => {
        setTopTenPumps(response);
      });

    fetch(
      `/api/admin/useranalytics/distributorClicks/originalPumps/${dateFrom}/${dateTo}`
    )
      .then((response) => response.json())
      .then((response) => {
        setOriginals(response);
      });

    fetch(
      `/api/admin/useranalytics/distributorClicks/replacementPumps/${dateFrom}/${dateTo}`
    )
      .then((response) => response.json())
      .then((response) => {
        setReplacements(response);
      });

    fetch(
      `/api/admin/useranalytics/distributorClicks/adapters/${dateFrom}/${dateTo}`
    )
      .then((response) => response.json())
      .then((response) => {
        setAdapters(response);
      });
  };

  const getDistributorClicks = () => {
    fetch(`/api/admin/pumps/distributorClicks/${dateFrom}/${dateTo}`)
      .then((response) => response.blob())
      .then(function (blob) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "AhlsellData.xlsx";
        document.body.appendChild(link);
        link.click();
      });
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
      },
      sort: {
        enable: true,
      },
    },
  };

  const labels = usersPerDay.map((el) => {
    return el.date.substring(0, 10);
  });

  const userNums = usersPerDay.map((el) => {
    return el.uniqueVisits;
  });

  //Searched pump where Ahlsell button were clicked on any of the replacement pumps
  const originalPumpId = originals.map(({ name }) => {
    return name;
  });

  const amoutOriginals = originalPumpId.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  //Ahlsell button clicked on Replacement Pumps
  const replacementPumpId = replacements.map(({ replacementName }) => {
    return replacementName;
  });

  const amountReplacments = replacementPumpId.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  //Ahlsell button click on adapters
  const adapterId = adapters.map(({ description }) => {
    return description;
  });
  const amountAdapters = adapterId.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  const search = searceshPerDay.map(({ timestamp }) => {
    return timestamp;
  });

  const searchPerDay = search.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  const test = Object.keys(searchPerDay).map((val) => {
    return val.toString().substring(0, 10);
  });

  const amountSearchperDay = test.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});

  // Top 10 searched pumps
  const topTen = {
    pump: topTenPumps.map(({ pump }) => {
      return pump;
    }),
    count: topTenPumps.map(({ count }) => {
      return count;
    }),
  };

  const uniqueVisits = {
    labels,
    datasets: [
      {
        label: "Unique users per day",
        data: userNums,
        backgroundColor: "#2d819e",
      },
    ],
  };

  const searchesPerDay = {
    labels: Object.keys(amountSearchperDay),
    datasets: [
      {
        label: "Pump searches per day",
        data: Object.values(amountSearchperDay),
        backgroundColor: "#2d819e",
      },
    ],
  };

  const topSearched = {
    labels: topTen.pump,
    datasets: [
      {
        label: "Top 10 searched pumps",
        data: topTen.count,
        backgroundColor: "#2d819e",
      },
    ],
  };

  const originalPumps = {
    labels: Object.keys(amoutOriginals),
    datasets: [
      {
        label: "Original pumps - Ahlsell button",
        data: Object.values(amoutOriginals),
        backgroundColor: "#2d819e",
      },
    ],
  };
  const replacementPumps = {
    labels: Object.keys(amountReplacments),
    datasets: [
      {
        label: "Replacement pumps - Ahlsell button",
        data: Object.values(amountReplacments),
        backgroundColor: "#2d819e",
      },
    ],
  };

  const adapterClicks = {
    labels: Object.keys(amountAdapters),
    datasets: [
      {
        label: "Adapters - Ahlsell button",
        data: Object.values(amountAdapters),
        backgroundColor: "#2d819e",
      },
    ],
  };

  // getting todays date to update second date input
  var utc = new Date().toJSON().slice(0, 10).replace(/-/g, "-").toString();

  return (
    <div className="Wrapper">
      <section>
        <h3>Statistics</h3>
        <Bar options={options} data={uniqueVisits} />
        <Bar options={options} data={searchesPerDay} />
      </section>
      <section>
        <h3>Top 10 searched pumps</h3>
        <Bar options={options} data={topSearched} />
      </section>
      <section>
        <h3>Ahlsell statistics</h3>
        <Bar options={options} data={originalPumps} />
        <Bar options={options} data={replacementPumps} />
        <Bar options={options} data={adapterClicks} />

        <div className="dates">
          <div className="date">
            <p>Date from</p>
            <input
              type="date"
              id="dateFrom"
              defaultValue={"2021-01-01"}
              onChange={(e) => {
                setDateFrom(e.target.value);
              }}
            />
          </div>
          <div className="date">
            <p>Date to</p>
            <input
              type="date"
              id="dateTo"
              defaultValue={utc}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </div>
          <button
            className="addButton"
            style={{ margin: "0rem" }}
            onClick={() => selectedDates()}
          >
            Change dates
          </button>
          <button
            className="addButton"
            style={{ margin: "0rem 0rem 0rem auto" }}
            onClick={(e) => getDistributorClicks()}
          >
            Export Ahlsell data from database
          </button>
        </div>
      </section>
    </div>
  );
};
