import { proxy, subscribe } from "valtio";

export const LogInState = proxy({
  status: false,
  email: null,
  role: null,
});

// State related to graphical rendering
export const ViewState = proxy({
  width: window.innerWidth,
});

window.addEventListener("resize", () => {
  ViewState.width = window.innerWidth;
});

// Language and market configuration state
export const LocaleState = proxy({
  language: "",
});

export const usersState = proxy({
  allUsers: [],
});

export const ConnectionsState = proxy({
  mainsConnection: [],
  pipeConnection: [],
});

// Metadata that always gets fetched from API
export const MetadataState = proxy({
  adapters: {},
  brands: {},
  pumps: [],
  texts: {},
  types: {},
  comments: [],
  allAdapters: [],
  allAccessories: [],
});

// Selected brand from xylem only for search
export const XylemOnlyMetadataState = proxy({
  brands: {},
  pumps: [],
  texts: {},
});

export const Store = proxy({
  filteredPumps: [],
  existingPump: null,
  existingPumpId: null,
  replacementPumps: [],
  filter: { brand: null },
  fuse: null,
  matchedPumps: [],
});

export const SelectionState = proxy({
  selectedPump: null,
});

export const PumpState = proxy({
  existingPump: null,
  replacementPumps: [],
  pumpAccessory: [],
});

export const UpdatedState = proxy({
  updatedPump: {},
  updatedAdapter: {},
  updatedComment: {},
  updatedReplacement: {},
  newReplacementPump: {},
  newAdapter: {},
  newComment: {},
  newPump: {},
  newImg: {},
  newPdf: {},
});

export const FileState = proxy({
  allFiles: [],
  imagePreview: [],
});

export const LanguageState = proxy({
  allLanguages: [],
});

export const ReplacementPumpsState = proxy({
  allReplacements: [],
  allReplacementTypes: [],
});

export const UserAnalyticsState = proxy({
  distributorClicks: [],
  originalPumps: [],
  replacementPumps: [],
});
export const StatusCode = proxy({
  setModal: false,
});

export const showStatusModal = () => {
  StatusCode.setModal = true;
  setTimeout(() => {
    StatusCode.setModal = false;
  }, 4000);
};

// Fetch metadata
subscribe(LocaleState, () => {
  const language = "en";

  fetch(`/api/admin/metadata/brands?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.brands = response;
    });

  fetch(`/api/admin/metadata/pumps?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.pumps = response;
      XylemOnlyMetadataState.pumps = response;
    });

  fetch(`/api/admin/metadata/adapters?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.adapters = response;
    });

  fetch(`/api/admin/metadata/texts?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.texts = response;
      XylemOnlyMetadataState.texts = response;
    });

  fetch(`/api/admin/metadata/types?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.types = response;
    });

  fetch(`/api/admin/comments`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.comments = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/adapters`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.allAdapters = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/allAccessories`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.allAccessories = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  fetch(`/api/admin/users`)
    .then((response) => response.json())
    .then((response) => {
      usersState.allUsers = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/metadata/pumps/mainsConnection`)
    .then((response) => response.json())
    .then((response) => {
      ConnectionsState.mainsConnection = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/metadata/pumps/pipeconnections`)
    .then((response) => response.json())
    .then((response) => {
      ConnectionsState.pipeConnection = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/files`)
    .then((response) => response.json())
    .then((response) => {
      FileState.allFiles = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/languages`)
    .then((response) => response.json())
    .then((response) => {
      LanguageState.allLanguages = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/replacements`)
    .then((response) => response.json())
    .then((response) => {
      ReplacementPumpsState.allReplacements = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  fetch(`/api/admin/replacements/types`)
    .then((response) => response.json())
    .then((response) => {
      ReplacementPumpsState.allReplacementTypes = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  // endpoints for getting xylem pumps only
  fetch(`/api/admin/metadata/xylembrands?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      XylemOnlyMetadataState.brands = response;
    });

  fetch(`/api/admin/metadata/xylempumps?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      XylemOnlyMetadataState.pumps = response;
    });

  fetch(`/api/metadata/xylempumps?language=${language}`)
    .then((response) => response.json())
    .then((response) => {
      XylemOnlyMetadataState.pumps = response;
    });

  fetch(`/api/admin/useranalytics`)
    .then((response) => response.json())
    .then((response) => {
      UserAnalyticsState.userAnalytics = response;
    });

  fetch(`/api/admin/useranalytics/distributorClicks`)
    .then((response) => response.json())
    .then((response) => {
      UserAnalyticsState.distributorClicks = response;
    });

  // fetch(`/api/admin/useranalytics/distributorClicks/originalPumps`)
  //   .then((response) => response.json())
  //   .then((response) => {
  //     UserAnalyticsState.originalPumps = response;
  //   });
  // fetch(`/api/admin/useranalytics/distributorClicks/replacementPumps`)
  //   .then((response) => response.json())
  //   .then((response) => {
  //     UserAnalyticsState.replacementPumps = response;
  //   });
});

// Setting language triggers callback function for subscribe above, causing inital API calls to happen
LocaleState.language = localStorage.getItem("language") || "en";

const translateNameToId = (array, name) => {
  for (const object of array) {
    if (object.name === name) {
      return object.id;
    }
  }

  return null;
};

export const fetchPumpData = () => {
  const selectedPump = SelectionState.selectedPump;
  const language = LocaleState.language;

  fetch(`/api/admin/accessories/${selectedPump}`)
    .then((response) => response.json())
    .then((response) => {
      PumpState.pumpAccessory = response;
    });

  if (typeof selectedPump === "string") {
    fetch(`/api/admin/pump/${selectedPump}?language=en`)
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      })
      .then((response) => {
        response.pipeConnection = translateNameToId(
          ConnectionsState.pipeConnection,
          response.pipeConnection
        );
        response.mainsConnection = translateNameToId(
          ConnectionsState.mainsConnection,
          response.mainsConnection
        );
        PumpState.existingPump = response;
      });

    fetch(`/api/admin/pump/replacement/${selectedPump}?language=XX&validated=0`)
      .then((response) => response.json())
      .then((response) => {
        for (const replacement of response) {
          if (replacement.comments) {
            replacement.comments = replacement.comments.map((comment) =>
              comment.replace("$replacements.comments.", "")
            );
          }
        }
        PumpState.replacementPumps = response;
      });
  } else {
    PumpState.existingPump = null;
    PumpState.replacementPumps = [];
  }
};

/* Update pump*/
/* fetch("http://10.130.0.129:3333/api/admin/pump", {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  mode: "cors",
  body: JSON.stringify(),
})
  .then((response) => response.json())
  .catch((error) => {
    console.error("Error:", error);
  }); */

subscribe(SelectionState, fetchPumpData);
subscribe(LocaleState, fetchPumpData);

/** store definations for users endpoints **/

export const fetchAllusers = () => {
  fetch(`/api/admin/users`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    })
    .then((response) => {
      usersState.allUsers = response;
    });
};

export const fetchAllAdapters = () => {
  fetch(`/api/admin/adapters`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    })
    .then((response) => {
      MetadataState.allAdapters = response;
    });
};

export const fetchAllAccessories = () => {
  fetch(`/api/admin/allAccessories`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    })
    .then((response) => {
      MetadataState.allAccessories = response;
    });
};

export const fetchAllReplacementsPumps = () => {
  fetch(`/api/admin/replacements`)
    .then((response) => response.json())
    .then((response) => {
      ReplacementPumpsState.allReplacements = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const fetchAllComments = () => {
  fetch(`/api/admin/comments`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.comments = response;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const fetchAllPumps = () => {
  fetch(`/api/admin/metadata/pumps?language=en`)
    .then((response) => response.json())
    .then((response) => {
      MetadataState.pumps = response;
    });
};

// subscribe(LocaleState, fetchAllusers);
export const logOut = () => {
  sessionStorage.removeItem("token");
  LogInState.status = false;
  LogInState.email = null;
  LogInState.role = null;
  LogInState.id = null;
};
