import React from "react";
import { ConnectionsState } from "../../NewStore";
import { useSnapshot } from "valtio";

export const MainConnections = (props) => {
  const connections = useSnapshot(ConnectionsState);

  const handleChange = (event) => {
    if (props.setPumpData) {
      props.setPumpData({
        ...props.pumpData,
        mainsConnection: event.target.value,
      });
    }
    if (props.setNewPump) {
      props.setNewPump({
        ...props.newPump,
        mainsConnection: event.target.value,
      });
    }
  };
  return (
    <select
      onChange={(event) => {
        handleChange(event);
      }}
      className="changeBorder"
      id="mainsConnection"
      name="mainsConnection"
      value={
        props.pumpData?.mainsConnection || props.newPump?.mainsConnection || ""
      }
      style={props.style}
    >
      {connections.mainsConnection?.map((item, idx) => (
        <option key={idx} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
};
