import { Users } from "./SideNav/Users";
import { Pumps } from "./SideNav/Pumps";
import { ReplacementPumpsTab } from "./SideNav/ReplacementPumpsTab";
import { Comments } from "./SideNav/Comments";
import { Files } from "./SideNav/Files";
import { Statistics } from "./SideNav/Statistics";
import { Guidelines } from "./SideNav/Guidelines";
import { Profile } from "./SideNav/Profile";
import { Adapters } from "./SideNav/Adapters";
import { DataExport } from "./SideNav/DataExport";
import { Accessories } from "./SideNav/Accessories";

import { FiUsers } from "react-icons/fi";
import { BiGasPump } from "react-icons/bi";
import { FaRegCommentAlt, FaExchangeAlt } from "react-icons/fa";
import { VscFiles } from "react-icons/vsc";
import { ImStatsBars } from "react-icons/im";
import { AiOutlineUser } from "react-icons/ai";
import { MdSettingsInputComponent } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import { HiBookOpen } from "react-icons/hi";

export const Navigation = [
  {
    path: "/Users",
    component: Users,
    icon: FiUsers,
    title: "Users",
    allowedRoles: ["Admin"],
  },
  {
    path: "/Pumps",
    component: Pumps,
    icon: BiGasPump,
    title: "Pumps",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/ReplacementPumpsTab",
    component: ReplacementPumpsTab,
    icon: FaExchangeAlt,
    title: "Change replacements",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/Adapters",
    component: Adapters,
    icon: MdSettingsInputComponent,
    title: "Adapters",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/Accessories",
    component: Accessories,
    icon: HiPlus,
    title: "Accessories",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/Comments",
    component: Comments,
    icon: FaRegCommentAlt,
    title: "Comments",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/Files",
    component: Files,
    icon: VscFiles,
    title: "Files",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/DataExport",
    component: DataExport,
    icon: FiDownload,
    title: "Data export",
    allowedRoles: ["Admin", "Moderator"],
  },
  {
    path: "/Statistics",
    component: Statistics,
    icon: ImStatsBars,
    title: "Statistics",
    allowedRoles: ["Admin", "Moderator", "Viewer"],
  },
  {
    path: "/Guidelines",
    component: Guidelines,
    icon: HiBookOpen,
    title: "Guidelines",
    allowedRoles: ["Admin", "Moderator", "Translator", "Viewer"],
  },
  {
    path: "/Profile",
    component: Profile,
    icon: AiOutlineUser,
    title: "Profile settings",
    allowedRoles: ["Admin"],
  },
];
