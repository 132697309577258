import React, { useState } from "react";
import { NewUser } from "../New/NewUser";
import { EditUser } from "../Edit/EditUser";
import { EditPassword } from "../Edit/EditPassword";
import { DeleteModal } from "../DeleteModal";

import { useSnapshot } from "valtio";
import {
  usersState,
  fetchAllusers,
  logOut,
  showStatusModal,
} from "../../NewStore";

export const Users = () => {
  const [newUser, setNewUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editPass, setEditPass] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userToEditId, setUserToEditId] = useState(null);

  const users = useSnapshot(usersState);

  const toggleNewUser = () => {
    setNewUser(!newUser);
  };
  const toggleEditUser = () => {
    setEditUser(!editUser);
  };
  const toggleEditPass = () => {
    setEditPass(!editPass);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const deleteUser = () => {
    fetch(`/api/admin/users/${userToEditId}`, {
      method: "DELETE",

      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllusers();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const usersView = users.allUsers.map((elem, idx) => {
    return (
      <tr key={idx}>
        <td>{elem.email}</td>
        <td>{elem.role}</td>
        <td>
          <button
            className="addButton"
            onClick={() => {
              setEditUser(true);
              setUserToEditId(elem.id);
            }}
          >
            Edit user
          </button>
        </td>
        <td>
          <button
            className="addButton"
            onClick={() => {
              setEditPass(true);
              setUserToEditId(elem.id);
            }}
          >
            Edit Password
          </button>
        </td>
        <td>
          <button
            className="deleteButton"
            onClick={() => {
              setDeleteModal(true);
              setUserToEditId(elem.id);
            }}
          >
            Delete user
          </button>
        </td>
      </tr>
    );
  });
  return (
    <div className="Wrapper">
      <section>
        <h3>Users</h3>
        <button className="addButton" onClick={() => setNewUser(true)}>
          Add new user
        </button>
        <div>
          <table className="table table-hover align-middle">
            <tbody>
              <tr>
                <td>
                  <b>Username</b>
                </td>
                <td>
                  <b>Role</b>
                </td>
                <td>
                  <b>&nbsp;</b>
                </td>
                <td>
                  <b>&nbsp;</b>
                </td>
                <td>
                  <b>&nbsp;</b>
                </td>
              </tr>
              {usersView}
            </tbody>
          </table>
        </div>
      </section>
      {newUser && <NewUser toggleModal={toggleNewUser} />}
      {editUser && <EditUser id={userToEditId} toggleModal={toggleEditUser} />}
      {editPass && (
        <EditPassword id={userToEditId} toggleModal={toggleEditPass} />
      )}
      {deleteModal && (
        <DeleteModal
          toggleModal={toggleDeleteModal}
          deleteState={deleteUser}
          id={userToEditId}
        />
      )}
    </div>
  );
};
