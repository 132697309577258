import React, { useState } from "react";
import { useSnapshot } from "valtio";

import {
  MetadataState,
  fetchAllAdapters,
  logOut,
  showStatusModal,
} from "../../NewStore";
import { NewAdapter } from "../New/NewAdapter";
import { EditAdapter } from "../Edit/EditAdapter";
import { DeleteModal } from "../DeleteModal";
import { PumpListModal } from "../PumpListModal";
import { useEffect } from "react";

export const Adapters = () => {
  const [newAdapter, setNewAdapter] = useState(false);
  const [editAdapter, setEditAdapter] = useState(false);
  const [selectedAdapter, setSelectedAdapter] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [pumpList, setPumpList] = useState([]);
  const [pumpListModal, setPumpListModal] = useState(false);

  const MetadataSnap = useSnapshot(MetadataState);
  const allAdapters = MetadataSnap.allAdapters;

  const toggleModalNewAdapter = () => {
    setNewAdapter(!newAdapter);
  };

  const toggleModalEditAdapter = () => {
    setEditAdapter(!editAdapter);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const openEditAdapter = (adapter) => {
    setEditAdapter(true);
    setSelectedAdapter(adapter);
  };

  const togglePumpListModalModal = () => {
    setPumpListModal(!pumpListModal);
  };

  const deleteAdapter = () => {
    fetch(`/api/admin/adapters/${selectedAdapter}`, {
      method: "DELETE",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();

        return response.json();
      })
      .then((response) => {
        setPumpList(response);
        setDeleteModal(false);
        fetchAllAdapters();
        if (response.length > 0) {
          setPumpListModal(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="Wrapper">
      <section>
        <h3>Adapters</h3>
        <div className="adapterSection">
          <button className="addButton" onClick={() => setNewAdapter(true)}>
            Create new adapter
          </button>
          <div className="editAdapter">
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "26.5rem" }}>ID</td>
                  <td style={{ width: "16rem" }}>Description</td>
                </tr>
              </tbody>
            </table>
            {Object.keys(allAdapters).map((adapter, idx) => (
              <table className="table table-sm" key={`"adapter"_${idx}`}>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={allAdapters[adapter].id}
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={allAdapters[adapter].description}
                        readOnly
                      />
                    </td>
                    <td>
                      <button
                        style={{ margin: "0" }}
                        className="addButton"
                        onClick={() => openEditAdapter(adapter)}
                      >
                        Edit
                      </button>

                      <button
                        style={{ margin: "0 0 0 1rem" }}
                        className="deleteButton"
                        onClick={() => {
                          setSelectedAdapter(allAdapters[adapter].id);
                          setDeleteModal(true);
                        }}
                      >
                        Delete adapter
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </div>
        </div>
      </section>
      {pumpListModal && (
        <PumpListModal
          toggleModal={togglePumpListModalModal}
          pumpList={pumpList}
        />
      )}
      {newAdapter && <NewAdapter toggleModal={toggleModalNewAdapter} />}
      {editAdapter && (
        <EditAdapter
          toggleModal={toggleModalEditAdapter}
          adapter={selectedAdapter}
        />
      )}
      {deleteModal && (
        <DeleteModal
          toggleModal={toggleDeleteModal}
          deleteState={deleteAdapter}
        />
      )}
    </div>
  );
};
