import React, { useState } from "react";

import { observer } from "mobx-react";

import { SearchArea } from "../SearchArea";
import { PumpEdit, ReplacementPumps } from "../../components";
import { PumpAccessories } from "../PumpAccessories";
import { MetadataState, PumpState } from "../../NewStore";

import { useSnapshot } from "valtio";
import { NewPump } from "../New/NewPump";
import { SaveModal } from "../SaveModal";

export const Pumps = observer(() => {
  const MetadataSnap = useSnapshot(MetadataState);
  const PumpSnap = useSnapshot(PumpState);
  const [newPump, setNewPump] = useState(false);
  const [save, setSave] = useState(false);

  const toggleModal = () => {
    setNewPump(!newPump);
  };
  const toggleModalSave = () => {
    setSave(!save);
  };

  return (
    <div className="Wrapper">
      <section className="search-section">
        <SearchArea />
      </section>
      <div>
        {/* ___________Button to add new pump__________________________ */}
        <button className="addButton" onClick={() => setNewPump(true)}>
          Add new pump
        </button>
      </div>
      {PumpSnap.existingPump && (
        <div>
          <section className="pumpedit-section">
            <h3>Edit pump</h3>
            <PumpEdit brands={MetadataSnap.brands} types={MetadataSnap.types} />
          </section>
          <section className="replacementpumps-section">
            <h3>Replacement pumps</h3>
            <ReplacementPumps
              pump={PumpSnap}
              pumps={PumpSnap.replacementPumps}
            />
          </section>
          <section>
            <h3>Accessories</h3>
            <PumpAccessories />
          </section>
        </div>
      )}
      {newPump && <NewPump toggleModal={toggleModal} />}
      {save && <SaveModal toggleModal={toggleModalSave} />}
    </div>
  );
});
