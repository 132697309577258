import React from "react";
import { BsExclamation } from "react-icons/bs";

export const StatusModal = (props) => {
  return (
    <div className="statusModal" style={{ textAlign: "center" }}>
      <h3>
        <BsExclamation className="warningSign" />
        500 Server Error
      </h3>
      <p>
        Something went wrong with the server communication, <br />
        please try again
      </p>
    </div>
  );
};
