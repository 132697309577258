import React from "react";
import { LanguageState } from "../../NewStore";
import { useSnapshot } from "valtio";

export const LanguageSelect = (props) => {
  const LanguageSnap = useSnapshot(LanguageState);

  const handleChange = (event) => {
    if (props.setComment) {
      props.setComment({ ...props.comment, language: event.target.value });
    }
    if (props.setUpdatedApapter && props.defaultValue === "en") {
      props.setUpdatedApapter({
        ...props.updatedAdapter,
        languageOne: event.target.value,
      });
    }
    if (props.setUpdatedApapter && props.defaultValue === "sv") {
      props.setUpdatedApapter({
        ...props.updatedAdapter,
        languageTwo: event.target.value,
      });
    }
    if (props.setUpdatedApapter) {
      let temp = props.updatedAdapter.language;
      props.setUpdatedApapter({
        ...props.updatedAdapter,
        language: [...props.updatedAdapter.language, event.target.value],
      });
    }
    if (props.setLanguage) {
      props.setLanguage(event.target.value);
    }
  };

  return (
    <select
      name=""
      id="languageSelect"
      onChange={(event) => {
        handleChange(event);
      }}
      style={props.errclass}
    >
      <option hidden>{props.defaultValue}</option>
      {LanguageSnap.allLanguages.map(({ name, code }, idx) => {
        return (
          <option key={idx} value={code}>
            {name}
          </option>
        );
      })}
    </select>
  );
};
