import { observer } from "mobx-react";

const Metadata = observer((props) => {
  const pump = props?.pump;
  if (!pump) return null;

  return (
    <div className="metadata">
      <textarea
        value={pump?.metadata || ""}
        onChange={(event) => props?.updateMetadata(event.currentTarget.value)}
      ></textarea>
      <button className="addButton" onClick={() => props?.onSave()}>
        Save
      </button>
    </div>
  );
});

export default Metadata;
