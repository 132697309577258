import React, { useState } from "react";

import { ImCross } from "react-icons/im";

import { SaveModal } from "../SaveModal";
import { ImageSelector } from "../Selectors/ImageSelector";
import { logOut } from "../../NewStore";
import { showStatusModal, fetchAllAccessories } from "../../NewStore";

export const NewAccessory = (props) => {
  const [save, setSave] = useState(false);
  const [errclass, setErrClass] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [accessory, setAccessory] = useState({
    productName: null,
    name: null,
    groupWord: null,
    order: null,
    subOrder: null,
    rsk: null,
    image: null,
    description: null,
  });

  const toggleModal = () => {
    setSave(!save);
  };

  const createNewAccessory = async () => {
    fetch(`/api/admin/newAccessory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(accessory),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        setSave(!save);
        props.toggleModal();
        fetchAllAccessories();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const validateForm = () => {
    if (
      !(
        typeof accessory.name === "string" &&
        typeof accessory.order === "string" &&
        typeof accessory.rsk === "string"
      )
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }
    return (
      typeof accessory.name === "string" &&
      typeof accessory.order === "string" &&
      typeof accessory.rsk === "string"
    );
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Create new accessory</h3>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}

        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <table>
          <tbody>
            <tr></tr>
            <tr>
              <th>Product name</th>
              <td>
                <input
                  type="text"
                  placeholder="Product"
                  onChange={(event) => {
                    setAccessory({
                      ...accessory,
                      productName: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Product</th>
              <td>
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(event) => {
                    setAccessory({ ...accessory, name: event.target.value });
                  }}
                  style={errclass}
                />
              </td>
            </tr>
            <tr>
              <th>Group word</th>
              <td>
                <input
                  type="text"
                  placeholder="Group word"
                  onChange={(event) => {
                    setAccessory({
                      ...accessory,
                      groupWord: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Order</th>
              <td>
                <input
                  type="text"
                  placeholder="Order"
                  onChange={(event) => {
                    setAccessory({ ...accessory, order: event.target.value });
                  }}
                  style={errclass}
                />
              </td>
            </tr>
            <tr>
              <th>Suborder</th>
              <td>
                <input
                  type="text"
                  placeholder="Suborder"
                  onChange={(event) => {
                    setAccessory({
                      ...accessory,
                      subOrder: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>Rsk</th>
              <td>
                <input
                  type="text"
                  placeholder="Rsk"
                  onChange={(event) => {
                    setAccessory({
                      ...accessory,
                      rsk: event.target.value,
                    });
                  }}
                  style={errclass}
                />
              </td>
            </tr>

            <tr>
              <th>Image</th>
              <td>
                <ImageSelector
                  fileImage={accessory.image}
                  setAccessory={setAccessory}
                  accessory={accessory}
                />
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <textarea
                  type="text"
                  placeholder="Description"
                  onChange={(event) => {
                    setAccessory({
                      ...accessory,
                      description: event.target.value,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
            } else {
              setErrMsg("Please fill up all required fields marked red");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={createNewAccessory} />
      )}
    </div>
  );
};
