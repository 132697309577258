import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import {
  MetadataState,
  PumpState,
  logOut,
  SelectionState,
  fetchAllPumps,
  showStatusModal,
} from "../NewStore";

import { useSnapshot } from "valtio";

import { MainConnections } from "./Selectors/MainConnections";
import { PipeConnections } from "./Selectors/PipeConnections";
import { ImageSelector } from "./Selectors/ImageSelector";
import { DatasheetSelector } from "./Selectors/DatasheetSelector";
import { SaveModal } from "./SaveModal";
import { DeleteModal } from "./DeleteModal";

const PumpEdit = observer((props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  const PumpSnap = useSnapshot(PumpState);
  const pump = PumpSnap.existingPump;
  const [pumpData, setPumpData] = useState({ ...pump });
  const [imagePreview, setImagePreview] = useState([]);
  const [filesEdit, setFilesEdit] = useState(true);
  const [imgStyle, setImgStyle] = useState({ border: "" });
  const [datasheetStyle, setDatasheetStyle] = useState({ border: "" });
  const [mainsStyle, setMainsStyle] = useState({ border: "" });
  const [pipeStyle, setPipeStyle] = useState({ border: "" });

  useEffect(() => {
    return () => {
      SelectionState.selectedPump = null;
      PumpState.existingPump = null;
    };
  }, []);

  useEffect(() => {
    if (pumpData?.image) {
      if (pumpData?.image !== pump?.image) {
        setImgStyle({
          border: "#14248A 2px solid",
        });
      } else {
        setImgStyle({ border: "" });
      }
    }
  }, [pumpData?.image]);

  useEffect(() => {
    if (pumpData?.datasheet) {
      if (pumpData?.datasheet !== pump?.datasheet) {
        setDatasheetStyle({
          border: "#14248A 2px solid",
        });
      } else {
        setDatasheetStyle({
          border: "",
        });
      }
    }
  }, [pumpData?.datasheet]);

  useEffect(() => {
    if (pumpData?.mainsConnection) {
      if (
        pumpData?.mainsConnection.toString() !==
        pump?.mainsConnection.toString()
      ) {
        setMainsStyle({
          border: "#14248A 2px solid",
        });
      } else {
        setMainsStyle({
          border: "",
        });
      }
    }
  }, [pumpData?.mainsConnection]);

  useEffect(() => {
    if (pumpData?.pipeConnection) {
      if (
        pumpData?.pipeConnection.toString() !== pump?.pipeConnection.toString()
      ) {
        setPipeStyle({
          border: "#14248A 2px solid",
        });
      } else {
        setPipeStyle({
          border: "",
        });
      }
    }
  }, [pumpData?.pipeConnection]);

  const resetValues = () => {
    setPumpData({ ...pump });
  };

  const setValues = () => {
    let inputs = document.getElementsByClassName("changeBorder");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].style.border = "1px solid black";
    }
  };

  if (pump.id !== pumpData.id) {
    resetValues();
  }

  const [save, setSave] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  if (!pump) return null;

  const onChange = (e) => {
    const { name, value } = e.target;
    setPumpData({ ...pumpData, [name]: value });
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleModal = () => {
    setSave(!save);
  };

  const saveToState = () => {
    fetch(`/api/admin/pump/${pump?.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(pumpData),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        setSave(!save);
        setValues();
        fetchAllPumps();
        // props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteFromDB = () => {
    SelectionState.selectedPump = null;
    PumpState.existingPump = null;
    // window.location.reload();
  };

  const deleteSelectedPump = () => {
    fetch(`/api/admin/pump/${pump?.id}`, {
      method: "DELETE",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        setDeleteModal(false);
        fetchAllPumps();
        deleteFromDB();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (pumpData?.image) setPreview(pumpData.image);
    if (!pumpData?.image) return;
  }, [pumpData.image]);

  const setPreview = (image) => {
    fetch(`/api/admin/file/${image}`)
      .then((response) => response.blob())
      .then((data) => {
        setImagePreview(URL.createObjectURL(data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (pump.id) {
      if (pump.id.startsWith("X")) {
        setFilesEdit(false);
      } else {
        setFilesEdit(true);
      }
    }
  }, [pump.id]);

  return !pump && !pumpData ? null : (
    <div>
      {pump && (
        <button className="resetButton" onClick={resetValues}>
          Reset pump fields
        </button>
      )}

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              <label htmlFor="productName">Product name</label>
            </td>
            <td>
              <input
                className="changeBorder"
                name="name"
                type="text"
                id="productName"
                placeholder="Product name"
                value={pumpData?.name}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.name !== pump.name
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="rsk">ID</label>
            </td>
            <td>
              <input
                className="changeBorder"
                type="text"
                placeholder="RSK"
                readOnly
                value={pumpData.id}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="rsk">RSK</label>
            </td>
            <td>
              <input
                className="changeBorder"
                name="rsk"
                type="text"
                id="rsk"
                placeholder="RSK"
                value={pumpData.rsk || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.rsk !== pump.rsk
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="overallLength">Overall length (mm)</label>
            </td>
            <td>
              <input
                className="changeBorder"
                name="overallLength"
                id="overallLength"
                type="number"
                placeholder="Select overall lenght"
                value={pumpData?.overallLength}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.overallLength !== pump.overallLength
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="eei">EEI</label>
            </td>
            <td>
              <input
                className="changeBorder"
                type="text"
                id="eei"
                placeholder="EEI"
                name="eei"
                value={pumpData?.eei || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.eei !== pump.eei
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="mei">MEI</label>
            </td>
            <td>
              <input
                className="changeBorder"
                id="mei"
                type="text"
                placeholder="MEI"
                name="mei"
                value={pumpData?.mei || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.mei !== pump.mei
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="efficiencyIndex">Efficiency index</label>
            </td>
            <td>
              <input
                className="changeBorder"
                type="text"
                id="efficiencyIndex"
                placeholder="Efficiency index"
                name="efficiencyIndex"
                value={pumpData?.efficiencyIndex || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.efficiencyIndex !== pump.efficiencyIndex
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="fluidTemperatureMin">
                Fluid temperature Min (°C)
              </label>
            </td>
            <td>
              <input
                className="changeBorder"
                type="text"
                id="fluidTemperatureMin"
                placeholder="Fluid temperature Min"
                name="fluidTemperatureMin"
                value={pumpData.fluidTemperatureMin || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.fluidTemperatureMin !== pump.fluidTemperatureMin
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="fluidTemperatureMax">
                Fluid temperature Max (°C)
              </label>
            </td>
            <td>
              <input
                className="changeBorder"
                type="text"
                id="fluidTemperatureMax"
                placeholder="Fluid temperature Max"
                name="fluidTemperatureMax"
                value={pumpData.fluidTemperatureMax || ""}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.fluidTemperatureMax !== pump.fluidTemperatureMax
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="brandSelect">Brand</label>
            </td>
            <td>
              <select
                className="changeBorder"
                id="brandSelect"
                name="brand"
                value={pumpData.brand}
                onChange={onChange}
                style={{
                  border:
                    pumpData?.brand !== pump.brand
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              >
                {Object.entries(MetadataSnap.brands).map(([k, v], idx) => (
                  <option key={idx} value={k}>
                    {v}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="mainsConnection">Main connection</label>
            </td>
            <td>
              <MainConnections
                mainsConnection={pumpData?.mainsConnection}
                setPumpData={setPumpData}
                pumpData={pumpData}
                pump={pump}
                style={mainsStyle}
                onChange={onChange}
                className="changeBorder"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="pipeConnection">Pipe connection</label>
            </td>
            <td>
              <PipeConnections
                pipeConnection={pumpData?.pipeConnection}
                setPumpData={setPumpData}
                pumpData={pumpData}
                pump={pump}
                style={pipeStyle}
                className="changeBorder"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="typeSelect">Types</label>
            </td>
            <td>
              <select
                className="changeBorder"
                id="typeSelect"
                name="type"
                onChange={onChange}
                style={{
                  border:
                    pumpData?.type !== pump.type
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              >
                {Object.entries(MetadataSnap.types).map(([k, v], idx) => (
                  <option key={idx} value={k}>
                    {v}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="series">Series</label>
            </td>
            <td>
              <input
                className="changeBorder"
                id="series"
                type="text"
                placeholder="Series"
                onChange={onChange}
                name="series"
                value={pumpData.series || ""}
                style={{
                  border:
                    pumpData?.series !== pump.series
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="subSeries">Subseries</label>
            </td>
            <td>
              <input
                className="changeBorder"
                id="subSeries"
                type="text"
                placeholder="Subseries"
                onChange={onChange}
                name="subSeries"
                value={pumpData.subSeries || ""}
                style={{
                  border:
                    pumpData?.subSeries !== pump.subSeries
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="model">Model</label>
            </td>
            <td>
              <input
                className="changeBorder"
                id="model"
                type="text"
                placeholder="Model"
                onChange={onChange}
                name="model"
                value={pumpData.model || ""}
                style={{
                  border:
                    pumpData?.model !== pump.model
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="modelExecution">Model execution</label>
            </td>
            <td>
              <input
                className="changeBorder"
                id="modelExecution"
                type="text"
                placeholder="Model execution"
                onChange={onChange}
                name="modelExecution"
                value={pumpData.modelExecution || ""}
                style={{
                  border:
                    pumpData?.modelExecution !== pump.modelExecution
                      ? "#14248A 2px solid"
                      : undefined,
                }}
              />
            </td>
          </tr>
          {filesEdit && (
            <>
              <tr>
                <td>
                  <label htmlFor="imgSelector">Select Image</label>
                </td>
                <td>
                  <ImageSelector
                    pumpImage={pumpData?.image}
                    setPumpData={setPumpData}
                    pumpData={pumpData}
                    pump={pump}
                    style={imgStyle}
                    showImage={setPreview}
                  />
                </td>
                <td style={{ position: "relative" }}>
                  <img className="pumpImage" src={imagePreview} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="datasheetSelector">Select Datasheet</label>
                </td>
                <td>
                  <DatasheetSelector
                    datasheet={pumpData?.datasheet}
                    setPumpData={setPumpData}
                    pumpData={pumpData}
                    pump={pump}
                    style={datasheetStyle}
                  />
                </td>
                <td>
                  <p
                    id="pdfName"
                    className="displayedNames"
                    style={{ margin: "0" }}
                  ></p>
                </td>
              </tr>
            </>
          )}
          <tr>
            <td>
              <label htmlFor="isValidated">Validated data</label>
            </td>
            <td>
              <input
                checked={!!pumpData.validated}
                onChange={() =>
                  setPumpData({
                    ...pumpData,
                    validated: pumpData.validated ? 0 : 1,
                  })
                }
                type="checkbox"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="editPumpButtons">
        <button className="addButton" onClick={() => setSave(true)}>
          Save
        </button>
        <button className="deleteButton" onClick={() => setDeleteModal(true)}>
          Delete pump
        </button>
      </div>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={saveToState} />
      )}
      {deleteModal && (
        <DeleteModal
          toggleModal={toggleDeleteModal}
          deleteState={deleteSelectedPump}
        />
      )}
    </div>
  );
});

export default PumpEdit;
