import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import { GrDown } from "react-icons/gr";
import { SearchReplacement } from "../SearchReplacement";
import { SaveModal } from "../SaveModal";
import {
  fetchAllReplacementsPumps,
  UpdatedState,
  logOut,
  MetadataState,
  showStatusModal,
} from "../../NewStore";
import { useSnapshot } from "valtio";

export const UpdateReplacementPump = (props) => {
  const MetadataSnap = useSnapshot(MetadataState.pumps);

  const PumpNameMap = {};
  for (const pump of MetadataSnap) {
    PumpNameMap[pump.id] = pump.name;
  }

  const UpdatedSnap = useSnapshot(UpdatedState);
  // const pump = PumpSnap.existingPump;
  const [save, setSave] = useState(false);
  const [singleReplacement, setSingleReplacement] = useState();
  const [brands, setBrands] = useState();
  const [pumpName, setPumpName] = useState([]);
  const selectedPump = UpdatedSnap.newReplacementPump;
  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  useEffect(() => {
    Promise.all([
      fetch(`/api/replacements/${props.id}`),
      fetch(`/api/metadata/brands`),
    ])
      .then((responses) => {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then((data) => {
        setSingleReplacement(data[0]);
        setBrands(data[1]);
        GetNamesFromIds(data[0]);
      })
      .catch((err) => console.log(err));
  }, [props.id]);
  //   const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setSingleUser({ ...singleUser, [name]: value });
  //   };

  const changeReplacementPump = async () => {
    fetch(`/api/admin/replacements/${singleReplacement.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldPump: singleReplacement,
        newPump: selectedPump,
      }),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllReplacementsPumps();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetNamesFromIds = (data) => {
    const matches = [];
    const pumpsArray = data.connectedPumps.split(", ");
    for (const key in PumpNameMap) {
      pumpsArray.forEach((value) => {
        if (value === key) {
          const obj = {
            pumpNames: PumpNameMap[key],
            pumpId: value,
          };
          matches.push(obj);
        }
      });
    }
    setPumpName(matches);
  };
  return (
    <div className="ModalWrapper">
      <section className="Modal scrollable">
        <ImCross className="crossIcon" onClick={props.toggleModal} />

        <div className="replacementDetails">
          <div>
            <h4>From </h4>

            {singleReplacement && (
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <td>Pump ID</td>
                    <td>{singleReplacement.id}</td>
                  </tr>
                  <tr>
                    <td>Pump Name</td>
                    <td>{singleReplacement.name}</td>
                  </tr>
                  <tr>
                    <td>Brand</td>
                    <td>{brands && brands[singleReplacement.brand]}</td>
                  </tr>
                  {/*  <tr>
                    <td>Connected Pump Ids</td>
                    <td>{singleReplacement.connectedPumps}</td>
                  </tr> */}
                  <tr>
                    <td>Connected pumps</td>
                    {pumpName.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td rowSpan="2">
                            {item.pumpId} - {item.pumpNames}
                          </td>
                        </tr>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="align-middle text-center">
            <GrDown style={{ fontSize: "4.5rem", height: "100%" }} />
          </div>
          <div>
            <h4>To </h4>

            <SearchReplacement />
            {UpdatedSnap.newReplacementPump && (
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <td>Pump ID</td>
                    <td>{UpdatedSnap.newReplacementPump.id}</td>
                  </tr>
                  <tr>
                    <td>Pump Name</td>
                    <td>{UpdatedSnap.newReplacementPump.name}</td>
                  </tr>
                  <tr>
                    <td>Brand</td>
                    <td>
                      {brands && brands[UpdatedSnap.newReplacementPump.brand]}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <button className="addButton" onClick={() => setSave(true)}>
          Change
        </button>
      </section>

      {save && (
        <SaveModal
          toggleModal={toggleModal}
          saveToState={changeReplacementPump}
        />
      )}
    </div>
  );
};
