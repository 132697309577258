import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { SaveModal } from "../SaveModal";
import { ImageSelector } from "../Selectors/ImageSelector";

import { fetchAllAdapters, logOut, showStatusModal } from "../../NewStore";

export const NewAdapter = (props) => {
  const [save, setSave] = useState(false);
  const [newAdapter, setNewAdapter] = useState({});
  const [errMsg, setErrMsg] = useState(null);
  const [errclass, setErrClass] = useState(null);

  const toggleModal = () => {
    setSave(!save);
  };
  /* 
  const displayImageName = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setImg(e.target.files);
    }
    setNewAdapter({ ...newAdapter, image: e.target.files[0].name });

    document.getElementById("newAdapterImg").innerHTML = e.target.files[0].name;
  }; */

  const getAutoGenId = () => {
    if (document.getElementById("autoID").checked) {
      fetch(`/api/admin/adapters/autoid`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setNewAdapter({ ...newAdapter, id: data });
          document.getElementById("id").value = data;
          document.getElementById("id").disabled = "disabled";
        })
        .catch((err) => console.log(err));
    } else {
      document.getElementById("id").disabled = "";
    }
  };

  const validateForm = () => {
    if (
      !(
        typeof newAdapter.id === "string" &&
        typeof newAdapter.description === "string"
      )
    ) {
      setErrClass({ border: "#c4014C 2px solid" });
    }
    return (
      typeof newAdapter.description === "string" &&
      typeof newAdapter.id === "string"
    );
  };

  // const saveToState = () => {
  //   UpdatedState.newAdapter = newAdapter;
  //   setSave(!save);
  //   console.log(UpdatedState.newAdapter);
  // };

  const createNewAdapter = async () => {
    fetch(`/api/admin/adapters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(newAdapter),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllAdapters();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Create new adapters</h3>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}
        <ImCross className="crossIcon" onClick={props.toggleModal} />

        <table>
          <tbody>
            <tr style={{ marginBottom: "5rem" }}>
              <td>ID</td>
              <td>
                <input
                  id="id"
                  type="text"
                  placeholder="ID"
                  onChange={(event) => {
                    setNewAdapter({ ...newAdapter, id: event.target.value });
                  }}
                  style={errclass}
                />
              </td>

              <td>
                <input id="autoID" onClick={getAutoGenId} type="checkbox" />
                &nbsp;
                <label htmlFor="autoGenerateId">Auto generate ID</label>
              </td>
            </tr>
            <tr>
              <td>RSK number</td>
              <td>
                <input
                  type="text"
                  placeholder="RSK"
                  onChange={(event) => {
                    setNewAdapter({ ...newAdapter, rsk: event.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <input
                  type="text"
                  placeholder="Description"
                  onChange={(event) => {
                    setNewAdapter({
                      ...newAdapter,
                      description: event.target.value,
                    });
                  }}
                  style={errclass}
                />
              </td>
            </tr>
            <tr>
              <td>Image</td>
              <td>
                <p id="newAdapterImg" style={{ margin: "0" }}></p>
                <ImageSelector
                  newAdapter={newAdapter}
                  setNewAdapter={setNewAdapter}
                  fileImage={newAdapter?.image}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
            } else {
              setErrMsg("Please fill up all required fields marked red");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={createNewAdapter} />
      )}
    </div>
  );
};
