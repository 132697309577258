import React, { useState } from "react";
import { useSnapshot } from "valtio";
import Fuse from "fuse.js";
import "../App.css";
import upArrow from "../assets/up_arrow.svg";
import downArrow from "../assets/down_arrow.svg";
import { HiOutlineSearch } from "react-icons/hi";
import { MetadataState, SelectionState } from "../NewStore";
import { useEffect } from "react";

// Remove later
const MAXRESULTS = 200;

const fuseOptions = {
  threshold: 0.4,
  distance: 50,
  keys: ["searchName", "rsk", "id", "name"],
};

export const SearchArea = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [matchedPumps, setMatchedPumps] = useState([]);
  const [placeholder, setPlaceholder] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const MetadataSnap = useSnapshot(MetadataState);

  const selectPump = (id, brand, name, rsk) => {
    SelectionState.selectedPump = id;
    if (rsk === null) {
      setPlaceholder(brand + " " + name);
    } else {
      setPlaceholder(brand + " " + name + ", " + rsk);
    }
    setExpanded(false);
  };

  // useEffect(() => {
  //   search();
  // }, [MetadataSnap.pumps]);

  const search = (brand) => {
    const filteredPumps = MetadataSnap.pumps
      .filter((pump) => pump.brand === brand || brand === null)
      .map((pump) => ({
        ...pump,
        searchName: MetadataSnap.brands[pump.brand] + " " + pump.name,
      }));

    const fuse = new Fuse(filteredPumps, fuseOptions);

    setExpanded(true);
    const fussy = fuse.search(document.querySelector("#fuse").value);

    setMatchedPumps(
      fussy.length
        ? fussy.slice(0, MAXRESULTS)
        : filteredPumps.length !== MetadataSnap.pumps.length
        ? filteredPumps.slice(0, MAXRESULTS)
        : []
    );
  };

  return (
    <div id="search-area">
      <div id="select-filters">
        <select
          id="filter-manufacturer"
          onChange={(event) => {
            let brand = event.target.value;
            brand = brand === "null" ? null : Number(brand);
            setSelectedBrand(brand);
            search(brand);
          }}
          onClick={() => setExpandedFilter(!expandedFilter)}
          onBlur={() => setExpandedFilter(false)}
          style={{
            backgroundImage: expandedFilter
              ? `url(${upArrow})`
              : `url(${downArrow})`,
          }}
        >
          <option value="null">{MetadataSnap.texts?.general?.brand}</option>
          {Object.entries(MetadataSnap.brands).map(([k, v], idx) => (
            <option key={idx} value={k}>
              {v}
            </option>
          ))}
        </select>
      </div>

      <div className="search-form">
        <button type="submit">
          <HiOutlineSearch />
        </button>
        <input
          type="search"
          autoComplete="off"
          id="fuse"
          placeholder={
            placeholder || MetadataSnap.texts?.general?.searchPlaceholder
          }
          onChange={() => search(selectedBrand)}
          onFocus={() => setExpanded(true)}
        />
        <button className="show-list" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <img src={upArrow} className="toggleArrow" alt="Up arrow" />
          ) : (
            <img src={downArrow} className="toggleArrow" alt="Down arrow" />
          )}
        </button>
      </div>
      <div id="search-list" className={expanded ? "active" : ""}>
        {matchedPumps.map((obj, index) => {
          const pump = obj?.item || obj;
          return (
            <div
              key={index}
              className="search-item"
              onClick={() =>
                selectPump(
                  pump?.id,
                  MetadataSnap.brands[pump?.brand],
                  pump?.name,
                  pump?.rsk
                )
              }
            >
              <b className="search-result manufacturer">
                {MetadataSnap.brands[pump?.brand]}
              </b>
              <span className="search-result name">{pump?.name}</span>
              <span className="search-result rsk">{pump?.rsk}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
