import React, { useState } from "react";
import { EditPassword } from "../Edit/EditPassword";
import { useSnapshot } from "valtio";
import { LogInState } from "../../NewStore";

export const Profile = () => {
  const [editPass, setEditPass] = useState(false);

  const loginData = useSnapshot(LogInState);

  const togglePassModal = () => {
    setEditPass(!editPass);
  };

  return (
    <div className="Wrapper">
      <section>
        <h3>Profile settings</h3>

        <button className="addButton" onClick={() => setEditPass(true)}>
          Edit password
        </button>
        <div className="userDetails">
          <p>{loginData.email}</p>
          <p>{loginData.role}</p>
        </div>
      </section>

      {/* <section>
        <button onClick={logOut}>Logout</button>
      </section> */}
      {editPass && <EditPassword toggleModal={togglePassModal} />}
    </div>
  );
};
