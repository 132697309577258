import React, { useState } from "react";
import { useSnapshot } from "valtio";
import { showStatusModal } from "../../NewStore";

import { MetadataState, fetchAllAccessories, logOut } from "../../NewStore";
import { ImageSelector } from "../Selectors/ImageSelector";
import { ImCross } from "react-icons/im";

import { SaveModal } from "../SaveModal";

import produce from "immer";

export const EditAccessory = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);
  let accessory = props.selectedAccessory;
  const [save, setSave] = useState(false);
  const [updatedAccessory, setUpdatedAccessory] = useState({ ...accessory });

  const updateAccessory = () => {
    fetch(`/api/admin/accessory/${accessory.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(updatedAccessory),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllAccessories();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const toggleModal = () => {
    setSave(!save);
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Edit Accessory</h3>
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div>
          <table>
            <tbody>
              <tr>
                <th>ID</th>
                <td>
                  <input
                    type="text"
                    id="accessoryId"
                    placeholder="Id"
                    defaultValue={accessory.id}
                    readOnly
                    // onChange={(event) => {
                    //   setAccessory({ ...accessory, id: event.target.value });
                    // }}
                  />
                </td>
              </tr>
              <tr>
                <th>Product name</th>
                <td>
                  <input
                    type="text"
                    placeholder="Product"
                    defaultValue={accessory.productName}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        productName: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Product</th>
                <td>
                  <input
                    type="text"
                    placeholder="Name"
                    defaultValue={accessory.name}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        name: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Group word</th>
                <td>
                  <input
                    type="text"
                    placeholder="Group word"
                    defaultValue={accessory.groupWord}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        groupWord: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Order</th>
                <td>
                  <input
                    type="text"
                    placeholder="Order"
                    defaultValue={accessory.order}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        order: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Suborder</th>
                <td>
                  <input
                    type="text"
                    placeholder="Suborder"
                    defaultValue={accessory.subOrder}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        subOrder: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>Rsk</th>
                <td>
                  <input
                    type="text"
                    placeholder="Rsk"
                    defaultValue={accessory.rsk}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        rsk: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>

              <tr>
                <th>Image</th>
                <td>
                  <ImageSelector
                    fileImage={accessory.image}
                    setUpdatedAccessory={setUpdatedAccessory}
                    updatedAccessory={updatedAccessory}
                  />
                </td>
              </tr>
              <tr>
                <th>Description</th>
                <td>
                  <textarea
                    type="text"
                    placeholder="Description"
                    defaultValue={accessory.description}
                    onChange={(event) => {
                      setUpdatedAccessory({
                        ...updatedAccessory,
                        description: event.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button className="addButton" onClick={() => setSave(true)}>
            Save
          </button>
        </div>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={updateAccessory} />
      )}
    </div>
  );
};
