import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { SaveModal } from "../SaveModal";
import { fetchAllusers, logOut, showStatusModal } from "../../NewStore";

// https://emailregex.com/
const emailValidationRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const passwordLengthRequirement = 6;

export const NewUser = (props) => {
  const roles = ["Viewer", "Admin", "Moderator", "Translator"];

  // Initialize newUser state with default role
  const [newUser, setNewUser] = useState({
    role: roles[0],
  });

  const [save, setSave] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const validateForm = () => {
    return (
      emailValidationRegex.test(newUser.email) &&
      roles.includes(newUser.role) &&
      typeof newUser.password === "string" &&
      newUser.password >= passwordLengthRequirement
    );
  };

  const createNewUser = async (value) => {
    fetch(`/api/admin/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(newUser),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllusers();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Add new user</h3>
        {errMsg && <p style={{ color: "#c4014C" }}>{errMsg}</p>}
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div className="userDetails">
          <label className="form-label" htmlFor="userEmail">
            Username:
          </label>
          <input
            className="form-control editInput"
            placeholder="userName@xylem.com"
            id="userEmail"
            name="email"
            type="text"
            onChange={handleInputChange}
          />
          <label htmlFor="role">Role</label>

          <select
            className="form-select editInput"
            placeholder="Role"
            type="text"
            id="role"
            name="role"
            onChange={handleInputChange}
          >
            {roles.map((role, idx) => {
              return (
                <option key={idx} value={role}>
                  {role}
                </option>
              );
            })}
          </select>
          <label htmlFor="Password">Password</label>
          <input
            className="form-control editInput"
            placeholder="password"
            type="password"
            id="password"
            name="password"
            onChange={handleInputChange}
          />
        </div>
        <button
          className="addButton"
          onClick={() => {
            if (validateForm()) {
              setSave(true);
            } else {
              setErrMsg("Please fill up all fields");
            }
          }}
        >
          Save
        </button>
      </section>
      {save && (
        <SaveModal toggleModal={toggleModal} saveToState={createNewUser} />
      )}
    </div>
  );
};
