import React from "react";
import { ReplacementPumpsState } from "../../NewStore";

import { useSnapshot } from "valtio";

export const ReplacementTypes = (props) => {
  const ReplacementPumpsSnap = useSnapshot(ReplacementPumpsState);

  const handleChange = (e) => {
    if (props.setUpdatedReplacement) {
      props.setUpdatedReplacement({
        ...props.updatedReplacement,
        replacementType: e.target.value,
        replacementTypeId: e.target.value,
      });
    }
    if (props.onChange) {
      const { name, value } = e.target;
      props.setNewRep({ ...props.newRep, [name]: value });
    }
  };
  return (
    <select
      name="replacementType"
      id="repType"
      onChange={(e) => handleChange(e)}
    >
      <option hidden>{props.Type || "Select replacement type"}</option>
      {Object.values(ReplacementPumpsSnap.allReplacementTypes).map(
        (type, idx) => {
          return (
            <option key={idx} value={type.id}>
              {type.name}
            </option>
          );
        }
      )}
    </select>
  );
};
