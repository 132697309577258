import React from "react";
import { Media } from "../../components";

import { PumpState } from "../../NewStore";

import { useSnapshot } from "valtio";

export const Files = () => {
  const PumpSnap = useSnapshot(PumpState);

  return (
    <div className="Wrapper">
      <section className="media-section">
        <Media
          pump={PumpSnap.replacementPumps}
          src={
            "/api/admin/file/" +
            PumpSnap.replacementPumps.datasheet +
            "?language=sv"
          }
        />
      </section>
    </div>
  );
};
