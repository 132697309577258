import React, { useState } from "react";

import { SaveModal } from "./SaveModal";
import { DeleteModal } from "./DeleteModal";
import { NewReplacementPump } from "./New/NewReplacementPump";
import { EditReplacementPump } from "./Edit/EditReplacementPump";
import { fetchPumpData, logOut, showStatusModal } from "../NewStore";

const ReplacementPumps = (props) => {
  const [save, setSave] = useState(false);
  const [del, setDel] = useState(false);
  const [newRep, setNewRep] = useState(false);
  const [editRep, setEditRep] = useState(false);
  const [repProps, setRepProps] = useState({});
  const [replacementToDeletesetDel, setReplacementToDeletesetDel] =
    useState(null);

  const pump = props.pump;

  if (!pump) return null;

  const toggleModal = () => {
    setSave(!save);
  };
  const toggleDelModal = () => {
    setDel(!del);
  };
  const toggleModalRep = () => {
    setNewRep(!newRep);
  };
  const toggleModalEditRep = () => {
    setEditRep(!editRep);
  };

  const openEditRep = (
    name,
    replacementType,
    comments,
    adapters,
    id,
    replacementTypeId
  ) => {
    setEditRep(!editRep);
    setRepProps({
      ...repProps,
      name: name,
      replacementType: replacementType,
      comments: comments,
      adapters: adapters,
      replacementId: id,
      replacementTypeId: replacementTypeId,
    });
  };

  const deleteReplacement = () => {
    fetch(
      `/api/admin/pumps/${pump.existingPump.id}/deleteReplacement/${replacementToDeletesetDel}`,
      {
        method: "DELETE",
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        fetchPumpData();
        setDel(!del);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="replacementPumps">
      <button className="addButton" onClick={() => setNewRep(true)}>
        Add new Replacement Pump
      </button>
      {pump.replacementPumps?.map(
        (
          { name, replacementType, replacementTypeId, comments, adapters, id },
          idx
        ) => {
          return (
            <div key={idx} className="replacements">
              <span>
                <b>{name}</b>
              </span>
              <div>
                <label htmlFor="replacementType">Replacement type: </label>
                <div key={replacementTypeId}>
                  <input
                    type="text"
                    id="replacementType"
                    value={replacementType}
                    readOnly
                  />
                </div>
                {adapters &&
                  adapters?.map(({ id, quantity }, idx) => {
                    return (
                      <div key={idx}>
                        <label htmlFor="adapters">Adapters:</label>
                        <div key={id}>
                          <input type="text" defaultValue={id} readOnly />
                        </div>
                        <label htmlFor="quantity">Adapter quantity:</label>
                        <div key={quantity}>
                          <input
                            type="number"
                            id="quantity"
                            defaultValue={quantity}
                            readOnly
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              {comments &&
                comments?.map((comments, idx2) => (
                  <div
                    key={"comment-" + idx + "-" + idx2}
                    className="commentComments"
                  >
                    <label htmlFor="comments">Comments:</label>
                    <div key={comments}>
                      <input
                        type="text"
                        id="comments"
                        value={comments}
                        readOnly
                      />
                    </div>
                  </div>
                ))}
              <div className="replacementButtons"></div>
              <button
                className="addButton"
                onClick={() =>
                  openEditRep(
                    name,
                    replacementType,
                    comments,
                    adapters,
                    id,
                    replacementTypeId
                  )
                }
              >
                Edit replacement
              </button>
              <button
                className="deleteButton"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  setReplacementToDeletesetDel(id);
                  setDel(true);
                }}
              >
                Remove replacement pump
              </button>
            </div>
          );
        }
      )}
      {/* <button className="addButton" onClick={() => setSave(true)}>
        Save
      </button> */}
      {save && <SaveModal toggleModal={toggleModal} />}
      {del && (
        <DeleteModal
          toggleModal={toggleDelModal}
          deleteState={deleteReplacement}
        />
      )}
      {newRep && <NewReplacementPump toggleModal={toggleModalRep} />}
      {editRep && (
        <EditReplacementPump
          toggleModal={toggleModalEditRep}
          repProps={repProps}
        />
      )}
    </div>
  );
};

export default ReplacementPumps;
