import "./App.sass";
import { useEffect } from "react";
import { SideNav } from "./components/SideNav/SideNav";
import { Login } from "./components/Login";
import jwtDecode from "jwt-decode";
// import { customFetch } from "./Services/AuthServices";
// import { IsLoggedIn } from "../src/Services/AuthServices";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSnapshot } from "valtio";
import { LogInState, StatusCode } from "./NewStore";

import { Navigation } from "./components/Navigation";
import { Profile } from "./components/SideNav/Profile";
import { StatusModal } from "./components/StatusModal";
import { Guidelines } from "./components/SideNav/Guidelines";
import { Statistics } from "./components/SideNav/Statistics";

const App = () => {
  const loginstatus = useSnapshot(LogInState);
  const status = useSnapshot(StatusCode);

  useEffect(() => {
    // customFetch("/api/pumps", {
    //   method: "GET",
    //   headers: {
    //     "Content-type": "application/json",
    //   },
    // });
    // fetch("/api/pumps")
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log(response);
    //   });
    if (
      sessionStorage.getItem("token") === false ||
      sessionStorage.getItem("token") === null
    ) {
      console.log("Unautorized");
      LogInState.status = false;
    } else {
      const decodedData = jwtDecode(sessionStorage.getItem("token"));

      LogInState.status = true;
      LogInState.email = decodedData.email;
      LogInState.role = decodedData.role;
      LogInState.id = decodedData.id;
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {loginstatus.status === true ? (
          <div>
            <SideNav />
            <Switch>
              <Route exact path="/">
                {LogInState.role == "Translator" ? (
                  <Guidelines />
                ) : (
                  <div>
                    {LogInState.role == "Viewer" ? <Statistics /> : <Profile />}
                  </div>
                )}
              </Route>
              {Navigation.map((navigationObject, idx) => {
                if (navigationObject.allowedRoles.includes(loginstatus.role)) {
                  return (
                    <Route exact path={navigationObject.path} key={idx}>
                      <navigationObject.component />
                    </Route>
                  );
                } else {
                  return null;
                }
              })}
            </Switch>
          </div>
        ) : (
          <Redirect to="/Login" />
        )}
        <Switch>
          <Route exact path="/Login">
            <Login></Login>
          </Route>
        </Switch>
      </BrowserRouter>
      {status.setModal && <StatusModal />}
    </div>
  );
};

export default App;
