import React from "react";
import { BsExclamation } from "react-icons/bs";

export const SaveModal = (props) => {
  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <div className="saveModal">
          <h3>
            <BsExclamation className="warningSign" />
            WARNING!
          </h3>
          <p>
            Are you sure you want to save your changes?
            <br /> Current data in the database will be<b> overwritten!</b>
          </p>
          <div className="saveButtons">
            <button onClick={props.saveToState} className="addButton">
              Save
            </button>
            <button onClick={props.toggleModal} className="deleteButton">
              Cancel
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
