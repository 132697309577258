import React, { useState } from "react";
import { useSnapshot } from "valtio";
import { ImCross } from "react-icons/im";

import { SaveModal } from "./SaveModal";
import { DeleteModal } from "./DeleteModal";
import {
  PumpState,
  MetadataState,
  SelectionState,
  logOut,
  showStatusModal,
  fetchPumpData,
} from "../NewStore";
import { BsDisplay } from "react-icons/bs";

//Modal for adding more accessories to a pump
const AccessoryList = (props) => {
  const accessoriesSnap = useSnapshot(MetadataState);
  const accessories = accessoriesSnap.allAccessories;
  const [addedAccessoryList, setAddedAccessoryList] = useState([]);
  const [save, setSave] = useState(false);

  const PumpSnap = useSnapshot(PumpState);
  const pump = PumpSnap.existingPump;
  const pumpRsk = pump.rsk;
  const toggleModal = () => {
    setSave(!save);
  };

  const addedAccessory = (rsk) => {
    setAddedAccessoryList((accessoriesRsk) => [...accessoriesRsk, rsk]);
  };

  const updatePumpAccessorries = () => {
    fetch(`/api/admin/pumps/${pumpRsk}/addAccessory/${addedAccessoryList}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(addedAccessoryList),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchPumpData();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="ModalWrapper">
      <section className="Modal">
        <h3>Add accessory</h3>
        <ImCross className="crossIcon" onClick={props.toggleModal} />
        <div>
          <select
            name=""
            id=""
            onChange={(e) => addedAccessory(e.target.value)}
          >
            <option hidden>Select accessory</option>
            {accessories.map(({ name, rsk, productName }, idx) => {
              return (
                <option key={"addAccessory_" + idx} value={rsk}>
                  {rsk} - {productName} - {name}
                </option>
              );
            })}
          </select>
        </div>
        {addedAccessoryList.map((rsk, idx) => {
          const item = accessories.find((i) => i.rsk === rsk);
          return (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={"addedAccessories" + idx}
            >
              {item.rsk} - {item.productName} - {item.name}
            </div>
          );
        })}

        <button
          className="addButton"
          onClick={() => {
            setSave(true);
          }}
        >
          Save
        </button>
        {save && (
          <SaveModal
            toggleModal={toggleModal}
            saveToState={updatePumpAccessorries}
          />
        )}
      </section>
    </div>
  );
};

//List of accessories belonging to a pump
export const PumpAccessories = (props) => {
  const [visible, setVisible] = useState(false);
  const accessorySnap = useSnapshot(PumpState);
  const accessories = accessorySnap.pumpAccessory;
  const [accessoryToDelete, setAccessoryToDelete] = useState([]);
  const [del, setDel] = useState(false);
  const PumpSnap = useSnapshot(PumpState);
  const pump = PumpSnap.existingPump;
  const pumpRsk = pump.rsk;

  const toggleModal = () => {
    setVisible(!visible);
  };

  const toggleDelModal = () => {
    setDel(!del);
  };

  const deleteAccessoryFromPump = () => {
    console.log(accessoryToDelete);
    fetch(`/api/admin/pumps/${pumpRsk}/removeAccessory/${accessoryToDelete}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(accessoryToDelete),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((data) => {
        fetchPumpData();
        setDel(!del);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <button className="addButton" onClick={toggleModal}>
        Add accessory
      </button>
      <div className="accessories">
        {accessories.map((accessories, idx) => {
          const accessoryRsk = accessories.accessoryRsk;
          const groupWord = accessories.groupWord;
          const product = accessories.product;
          return (
            <div
              key={"accessories_" + idx}
              style={{ margin: "2rem 0rem", display: "flex" }}
            >
              <div
                className="acc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0rem 1rem",
                }}
              >
                <label htmlFor="product">Product:</label>
                <input type="text" id="product" value={product} readOnly />
              </div>
              <div
                className="acc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0rem 1rem",
                }}
              >
                <label htmlFor="accessoryRsk">Rsk:</label>
                <input
                  type="text"
                  id="accessoryRsk"
                  value={accessoryRsk}
                  readOnly
                />
              </div>
              <div
                className="acc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0rem 1rem",
                }}
              >
                <label htmlFor="product">Group word:</label>
                <input type="text" id="groupWord" value={groupWord} readOnly />
              </div>
              <button
                className="deleteButton"
                onClick={() => {
                  setAccessoryToDelete(accessoryRsk);
                  setDel(true);
                }}
              >
                Remove accessory
              </button>
            </div>
          );
        })}
      </div>
      {visible && <AccessoryList toggleModal={toggleModal} />}
      {del && (
        <DeleteModal
          toggleModal={toggleDelModal}
          deleteState={deleteAccessoryFromPump}
        />
      )}
    </div>
  );
};

export default PumpAccessories;
