import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import { SaveModal } from "../SaveModal";
import { fetchAllusers, logOut, showStatusModal } from "../../NewStore";

export const EditUser = (props) => {
  const roles = ["Viewer", "Admin", "Moderator", "Translator"];

  const [save, setSave] = useState(false);
  const [singleUser, setSingleUser] = useState(null);

  const id = props.id;

  const toggleModal = () => {
    setSave(!save);
    // props.toggleModal();
  };

  useEffect(() => {
    fetch(`/api/admin/users/${id}`)
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      })
      .then((response) => {
        setSingleUser(response[0]);
      });
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSingleUser({ ...singleUser, [name]: value });
  };

  const updateUser = async () => {
    fetch(`/api/admin/users/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(singleUser),
      mode: "cors",
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) logOut();
        if (response.status === 500) showStatusModal();
        response.json();
      })
      .then((response) => {
        fetchAllusers();
        setSave(!save);
        props.toggleModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        console.log(error);
      });
  };

  return (
    <div className="ModalWrapper">
      {singleUser !== null && (
        <section className="Modal">
          <h3>Edit user</h3>

          <ImCross className="crossIcon" onClick={props.toggleModal} />

          <div className="userDetails">
            <label htmlFor="userEmail">Username:</label>
            <input
              // defaultValue={singleUser.email}
              id="userEmail"
              name="email"
              className="form-control editInput"
              type="text"
              value={singleUser.email}
              onChange={handleInputChange}
            />
            <label htmlFor="editRole">Role:</label>
            <select
              defaultValue={singleUser.role}
              placeholder="Role"
              type="text"
              id="editRole"
              name="role"
              className="form-select editInput"
              onChange={handleInputChange}
            >
              {roles.map((role, idx) => {
                return (
                  <option key={idx} value={role}>
                    {role}
                  </option>
                );
              })}
            </select>
          </div>
          <button className="addButton" onClick={() => setSave(true)}>
            Save
          </button>
        </section>
      )}
      {save && <SaveModal toggleModal={toggleModal} saveToState={updateUser} />}
    </div>
  );
};
